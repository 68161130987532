/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface UserInvitationDetailDto {
	status: string;
	/** @format date-time */
	expiresAt: string;
	username: string;
	email: string;
	title: string | null;
	surname: string | null;
	name: string;
	deploymentName: string | null;
	companyName: string | null;
	/** @format double */
	invitationID: number;
}

export interface RegisterResponseDto {
	qr: string;
}

export interface RegisterRequestDto {
	secret: string;
	password: string;
	username: string;
}

export interface SessionMaxAgeModel {
	/** @format double */
	maxAge: number;
}

export interface LoginResponseDto {
	tenant?: {
		name: string;
	};
	/** QR code for the user to scan in order to perform login. */
	qr?: string;
}

export interface LoginRequestDto {
	format: 'svg';
	first: boolean;
}

export interface GeofenceModel {
	/**
	 * The user ID of the tenant.
	 * @format double
	 * @example 1
	 */
	tenantID?: number | null;
	/**
	 * The user ID of the geofence owner.
	 * @format double
	 * @example 1
	 */
	userID?: number;
	/**
	 * The radius of the geofence.
	 * @format double
	 * @example 100
	 */
	radius: number;
	/**
	 * The longitude of the geofence.
	 * @format double
	 * @example 55.296249
	 */
	longitude: number;
	/**
	 * The latitude of the geofence.
	 * @format double
	 * @example 25.276987
	 */
	latitude: number;
	/** The name of the geofence. */
	name: string;
	/**
	 * Type ID of the geofence.
	 * @format double
	 * @example 2
	 */
	typeID: number;
	/**
	 * The unique identifier of the geofence.
	 * @format double
	 * @example 1
	 */
	id: number;
}

export type GetGeofenceResponseDto = GeofenceModel;

export interface PagedResultGetGeofenceResponseDto {
	/** @format double */
	total: number;
	entities: GetGeofenceResponseDto[];
}

export type GetGeofencesResponseDto = PagedResultGetGeofenceResponseDto;

export type CreateGeofenceResponseDto = GeofenceModel;

/** From T, pick a set of properties whose keys are in the union K */
export interface PickGeofenceModelExcludeKeysId {
	/**
	 * Type ID of the geofence.
	 * @format double
	 * @example 2
	 */
	typeID: number;
	/** The name of the geofence. */
	name: string;
	/**
	 * The latitude of the geofence.
	 * @format double
	 * @example 25.276987
	 */
	latitude: number;
	/**
	 * The longitude of the geofence.
	 * @format double
	 * @example 55.296249
	 */
	longitude: number;
	/**
	 * The radius of the geofence.
	 * @format double
	 * @example 100
	 */
	radius: number;
	/**
	 * The user ID of the geofence owner.
	 * @format double
	 * @example 1
	 */
	userID?: number;
	/**
	 * The user ID of the tenant.
	 * @format double
	 * @example 1
	 */
	tenantID?: number;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitGeofenceModelId = PickGeofenceModelExcludeKeysId;

export type CreateGeofenceRequestDto = OmitGeofenceModelId;

/** Make all properties in T optional */
export interface PartialCreateGeofenceRequestDto {
	/**
	 * Type ID of the geofence.
	 * @format double
	 * @example 2
	 */
	typeID?: number;
	/** The name of the geofence. */
	name?: string;
	/**
	 * The latitude of the geofence.
	 * @format double
	 * @example 25.276987
	 */
	latitude?: number;
	/**
	 * The longitude of the geofence.
	 * @format double
	 * @example 55.296249
	 */
	longitude?: number;
	/**
	 * The radius of the geofence.
	 * @format double
	 * @example 100
	 */
	radius?: number;
	/**
	 * The user ID of the geofence owner.
	 * @format double
	 * @example 1
	 */
	userID?: number;
	/**
	 * The user ID of the tenant.
	 * @format double
	 * @example 1
	 */
	tenantID?: number;
}

export type UpdateGeofenceRequestDto = PartialCreateGeofenceRequestDto;

export interface CreateGeofenceGroupResponseDto {
	/**
	 * The id of the created geofence group
	 * @format double
	 * @example 1
	 */
	id: number;
}

export interface CreateGeofenceGroupRequestDto {
	/**
	 * The IDs of the geofences that are part of the group
	 * @example [1,2,3]
	 */
	geofences?: number[];
	/** Description of the created geofence group to help identify it */
	description?: string;
	/** The name of the geofence group */
	name: string;
}

export interface GetGeofenceGroupByIDResponseDto {
	/** @format double */
	tenantID?: number | null;
	/**
	 * The geofences IDs that are part of the group
	 * @format double
	 * @example [1,2,3]
	 */
	totalGeofences?: number;
	/** Description of the created geofence group */
	description?: string;
	/** The name of the geofence group */
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetGeofenceGroupByIDResponseDto {
	/** @format double */
	total: number;
	entities: GetGeofenceGroupByIDResponseDto[];
}

/** Make all properties in T optional */
export interface PartialCreateGeofenceGroupRequestDto {
	/** The name of the geofence group */
	name?: string;
	/** Description of the created geofence group to help identify it */
	description?: string;
	/**
	 * The IDs of the geofences that are part of the group
	 * @example [1,2,3]
	 */
	geofences?: number[];
}

export type UpdateGeofenceGroupRequestDto = PartialCreateGeofenceGroupRequestDto;

export interface GetGeofenceGroupDetailByIDResponseDto {
	/** @format double */
	tenantID?: number | null;
	/**
	 * The geofences that are part of the group
	 * @example [{"id":1,"type":"geofence","name":"Dubai","latitude":25.276987,"longitude":55.296249,"radius":1000},{"id":2,"type":"geofence","name":"Abu Dhabi","latitude":24.4539,"longitude":54.3773,"radius":500}]
	 */
	geofences: GeofenceModel[];
	/** Description of the created geofence group */
	description?: string;
	/** The name of the geofence group */
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetGeofenceGroupDetailByIDResponseDto {
	/** @format double */
	total: number;
	entities: GetGeofenceGroupDetailByIDResponseDto[];
}

export interface GeofenceGroupModel {
	/**
	 * The user ID of the tenant.
	 * @format double
	 * @example 1
	 */
	tenantID?: number | null;
	/**
	 * The user ID of the geofence group owner.
	 * @format double
	 * @example 1
	 */
	userID?: number;
	/**
	 * The geofences IDs that are part of the group.
	 * @example [1,2,3]
	 */
	geofences?: number[];
	/** Description of the created geofence group. */
	description?: string;
	/** The name of the geofence group. */
	name: string;
	/**
	 * The unique identifier of the geofence group.
	 * @format double
	 * @example 1
	 */
	id: number;
}

export interface GetGeofenceDetailsInGroupResponseDto {
	geofenceGroups?: GeofenceGroupModel[];
	/**
	 * The type ID of the geofence
	 * @format double
	 * @example 2
	 */
	typeID: number;
	/**
	 * The user ID of the tenant
	 * @format double
	 * @example 1
	 */
	tenantID?: number | null;
	/**
	 * The user ID of the geofence owner
	 * @format double
	 * @example 1
	 */
	userID?: number;
	/**
	 * The radius of the geofence
	 * @format double
	 * @example 100
	 */
	radius: number;
	/**
	 * The longitude of the geofence
	 * @format double
	 * @example 55.296249
	 */
	longitude: number;
	/**
	 * The latitude of the geofence
	 * @format double
	 * @example 25.276987
	 */
	latitude: number;
	/** The name of the geofence */
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetGeofenceDetailsInGroupResponseDto {
	/** @format double */
	total: number;
	entities: GetGeofenceDetailsInGroupResponseDto[];
}

/** Make all properties in T optional */
export interface PartialGeofenceTypeDto {
	/** @format double */
	id?: number;
	name?: string;
	/** @format double */
	maxCount?: number;
	/** @format double */
	maxRadius?: number;
	validFrom?: string;
	validTo?: string;
}

export interface PagedResultPartialGeofenceTypeDto {
	/** @format double */
	total: number;
	entities: PartialGeofenceTypeDto[];
}

export interface CreateIdentityStoreDto {
	/** The password */
	password: string;
	/** The distinguished name (DN) to bind to the identity store. */
	bindDN: string;
	/** The base distinguished name (DN) for the identity store. */
	baseDN: string;
	/**
	 * A boolean indicating whether to use TLS for the connection.
	 * @example true
	 */
	useTLS: boolean;
	/** An CA certificate for TLS connections (optional). */
	caCertificate?: string;
	/**
	 * The port number on which the identity store server is listening.
	 * @format double
	 * @example 389
	 */
	port: number;
	/** The hostname or IP address of the identity store server. */
	host: string;
	/** An description of the identity store (optional). */
	description?: string;
	/** The name of the identity store. */
	name: string;
}

export enum EInvitationStatus {
	Pending = 'pending',
	Sent = 'sent',
	Failed = 'failed',
	Dismissed = 'dismissed',
	Accepted = 'accepted',
	Blocked = 'blocked',
}

/** Make all properties in T optional */
export interface PartialDetailedInvitationDto {
	/**
	 * The unique identifier of the invitation.
	 * @format double
	 * @example 1234
	 */
	id?: number;
	/**
	 * Identity store ID.
	 * @format double
	 * @example 1234
	 */
	identityStoreID?: number;
	/**
	 * The unique identifier of the user that the invitation is for.
	 * @format double
	 * @example 5678
	 */
	userID?: number;
	/** The status of the invitation. */
	status?: EInvitationStatus;
	/**
	 * The number of failed attempts to accept the invitation.
	 * @format double
	 * @example 0
	 */
	failedAttempts?: number;
	/** The date and time when the invitation was created. */
	createdAt?: string;
	/** The date and time when the invitation will expire. */
	expiresAt?: string;
	/**
	 * The unique identifier of the user that created the invitation.
	 * @format double
	 * @example 1234
	 */
	createdBy?: number;
	/** The unique identifier of the invitation. */
	uuid?: string;
	/**
	 * The email address of the user that the invitation is for.
	 * The email address associated with the invitation.
	 */
	email?: string;
	/**
	 * Invitation tenant ID
	 * @format double
	 * @example 1234
	 */
	tenantID?: number;
	/**
	 * The country code associated with the invitation.
	 * @example "321"
	 */
	countryCode?: string;
	/**
	 * The phone number associated with the invitation.
	 * @example "123456789"
	 */
	phoneNumber?: string;
}

export interface PagedResultPartialDetailedInvitationDto {
	/** @format double */
	total: number;
	entities: PartialDetailedInvitationDto[];
}

export interface IdentityStoreDto {
	useTLS: boolean;
	/** @format double */
	tenantId?: number;
	bindDN: string;
	baseDN: string;
	caCertificate?: string;
	/** @format double */
	port?: number;
	host: string;
	description?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultIdentityStoreDto {
	/** @format double */
	total: number;
	entities: IdentityStoreDto[];
}

export type RpcUpdateLDAPIdentityStoreResponseAsObject = object;

export interface UpdateIdentityStoreDto {
	/** The password for the identity store (optional). */
	password?: string;
	/** The bind DN for the identity store (optional). */
	bindDN?: string;
	/** The base DN for the identity store (optional). */
	baseDN?: string;
	/** Whether to use TLS for the identity store (optional). */
	useTLS?: boolean;
	/** The CA certificate for the identity store (optional). */
	caCertificate?: string;
	/**
	 * The port number of the identity store (optional).
	 * @format double
	 */
	port?: number;
	/** The host address of the identity store (optional). */
	host?: string;
	/** A description of the identity store (optional). */
	description?: string;
	/** The name of the identity store (optional). */
	name?: string;
}

export interface UserCachedFromIdentityStore {
	surname?: string;
	name: string;
	username: string;
	email?: string;
	uuid: string;
}

export interface PagedResultUserCachedFromIdentityStore {
	/** @format double */
	total: number;
	entities: UserCachedFromIdentityStore[];
}

export type GetUsersFromIdentityStoreResponseDto = PagedResultUserCachedFromIdentityStore;

export interface UserFromIdentityStore {
	/** @format double */
	status: number;
	surname?: string;
	name: string;
	username: string;
	email?: string;
	uuid: string;
}

export enum EMailSMTPSecurity {
	None = 'none',
	Starttls = 'starttls',
	Tls = 'tls',
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickCreateSMTPMailConfigurationDtoExcludeKeysCodeOrStatus {
	name: string;
	description?: string;
	email: string;
	host: string;
	/** @format double */
	port: number;
	username?: string;
	password?: string;
	sender: string;
	security: EMailSMTPSecurity;
	active?: boolean;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitCreateSMTPMailConfigurationDtoCodeOrStatus = PickCreateSMTPMailConfigurationDtoExcludeKeysCodeOrStatus;

export type CreateSMTPMailConfigurationRequest = OmitCreateSMTPMailConfigurationDtoCodeOrStatus;

export enum EMailConfigurationVerificationStatus {
	Verified = 'verified',
	Pending = 'pending',
	Failed = 'failed',
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickMailSMTPConfigurationExcludeKeysPassword {
	/** @format double */
	id: number;
	name: string;
	description?: string;
	/** @format date-time */
	createdAt: string;
	host: string;
	/** @format double */
	port: number;
	username: string;
	sender: string;
	security: EMailSMTPSecurity;
	active: boolean;
	verificationStatus: EMailConfigurationVerificationStatus;
	verificationEmail: string;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitMailSMTPConfigurationPassword = PickMailSMTPConfigurationExcludeKeysPassword;

export type ReadSMTPMailConfigurationResponse = OmitMailSMTPConfigurationPassword;

/** From T, pick a set of properties whose keys are in the union K */
export interface PickUpdateSMTPMailConfigurationDtoExcludeKeysCode {
	name?: string;
	description?: string;
	email?: string;
	host?: string;
	/** @format double */
	port?: number;
	username?: string;
	password?: string;
	sender?: string;
	security?: EMailSMTPSecurity;
	active?: boolean;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitUpdateSMTPMailConfigurationDtoCode = PickUpdateSMTPMailConfigurationDtoExcludeKeysCode;

export type UpdateSMTPMailConfigurationRequest = OmitUpdateSMTPMailConfigurationDtoCode;

export interface VerifySMTPMailConfigurationDto {
	verificationCode: string;
}

export enum EMailConfigurationType {
	Smtp = 'smtp',
	Gapi = 'gapi',
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickMailConfigurationExcludeKeysVerificationCode {
	/** @format double */
	id: number;
	name: string;
	description?: string;
	/** @format date-time */
	createdAt: string;
	sender: string;
	active: boolean;
	type: EMailConfigurationType;
	verificationStatus: EMailConfigurationVerificationStatus;
	verificationEmail: string;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitMailConfigurationVerificationCode = PickMailConfigurationExcludeKeysVerificationCode;

export type ReadMailConfigurationResponse = OmitMailConfigurationVerificationCode;

/** Make all properties in T optional */
export interface PartialOAuthClientResponseDto {
	clientID?: string;
	name?: string;
	description?: string;
	createdAt?: string;
	/** @format double */
	accessTokenValidity?: number;
}

/** Make all properties in T optional */
export interface PartialOAuthClientsCollectionResponseDto {
	clientID?: string;
	name?: string;
	createdAt?: string;
	expiresAt?: string;
}

export interface PagedResultPartialOAuthClientsCollectionResponseDto {
	/** @format double */
	total: number;
	entities: PartialOAuthClientsCollectionResponseDto[];
}

/** Make all properties in T optional */
export interface PartialCreateOAuthClientResponseDto {
	clientID?: string;
	name?: string;
	description?: string;
	/** @format double */
	accessTokenValidity?: number;
	secret?: string;
}

export interface CreateOAuthClientDto {
	name: string;
	description?: string;
	/** @format double */
	accessTokenValidity: number;
}

export interface UpdateOAuthClientDto {
	clientSecret?: string;
	name?: string;
	description?: string;
	/** @format double */
	accessTokenValidity?: number;
}

/** Make all properties in T optional */
export interface PartialRefreshClientSecretResponseDto {
	originalSecret?: string;
}

export interface AddPermissionsToOAuthClintRequestDto {
	tenantPermissionIDs?: number[];
	systemPermissionIDs?: number[];
}

export interface PermissionModel {
	/** Permission title */
	title: string;
	/** Permission description */
	description?: string;
	/** Permission name */
	name: string;
	/**
	 * Permission ID
	 * @format double
	 * @example 1
	 */
	id: number;
}

export interface OAuthClientPermissionsDto {
	tenantPermissions: PermissionModel[];
	systemPermissions: PermissionModel[];
}

export interface OAuthTokenResultDto {
	access_token: string;
	token_type: string;
	/** @format double */
	expires_in: number;
}

export interface OAuthAuthenticateDto {
	clientID: string;
	clientSecret: string;
}

export interface CreateWebhookResponseDto {
	/** @format double */
	webhookID: number;
}

export enum ESignatureMethod {
	Hmac = 'hmac',
	DeploymentPrivateKey = 'deployment_private_key',
}

export interface CreateWebhookRequestDto {
	caCertificate?: string;
	secret?: string;
	useTLS: boolean;
	signatureMethod: ESignatureMethod;
	eventTypeIDs: number[];
	url: string;
	oAuthClientID: string;
}

export interface UpdateWebhookResponseDto {
	/** @format double */
	webhookID: number;
}

export interface UpdateWebhookRequestDto {
	enabled?: boolean;
	caCertificate?: string;
	secret?: string;
	useTLS?: boolean;
	signatureMethod?: ESignatureMethod;
	eventTypeIDs?: number[];
	url?: string;
}

export interface EventTypeModel {
	name: string;
	/** @format double */
	id: number;
}

export interface WebhookModel {
	createdAt: string;
	deliveryFailure: boolean;
	caCertificate?: string;
	secret?: string;
	useTLS: boolean;
	signatureMethod: ESignatureMethod;
	enabled: boolean;
	eventTypes: EventTypeModel[];
	url: string;
	oAuthClientID: string;
	/** @format double */
	id: number;
}

export type GetWebhookByIdResponseDto = WebhookModel;

export interface GetWebhooksByOauthClientResponseDto {
	webhooks: WebhookModel[];
}

export interface GetEventTypesResponseDto {
	eventTypes: EventTypeModel[];
}

export enum EPAMTargetType {
	RDP = 'RDP',
	SSH = 'SSH',
	VNC = 'VNC',
}

export interface PAMTargetModel {
	/**
	 * Port of the PAM target
	 * @format double
	 * @example 3389
	 */
	port: number;
	/**
	 * Hostname of the PAM target
	 * @example "ad-rodc-1.example.com"
	 */
	hostname: string;
	/**
	 * PAM target description
	 * @example "Active Directory Read-Only Domain Controller 1"
	 */
	description?: string;
	/**
	 * PAM target name
	 * @example "AD RODC 1"
	 */
	name: string;
	/**
	 * PAM target type
	 * @example "ssh"
	 */
	type: EPAMTargetType;
	/**
	 * Tenant ID
	 * @format double
	 * @example 1
	 */
	tenantID?: number;
	/**
	 * PAM target ID
	 * @format double
	 * @example 1
	 */
	id: number;
}

export interface PagedResultPAMTargetModel {
	/** @format double */
	total: number;
	entities: PAMTargetModel[];
}

export type GetPAMTargetsResponseDto = PagedResultPAMTargetModel;

export interface DuplicatePAMTargetResponseDto {
	/** @format double */
	id: number;
}

export interface PAMTargetGroupModel {
	borderColor?: string;
	bgColor?: string;
	textColor?: string;
	description?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface PAMTargetDetailModel {
	groups: PAMTargetGroupModel[];
	isAudit?: boolean;
	authorizationRequired: boolean;
	useExcaliburCredentials: boolean;
	/** @format double */
	port: number;
	hostname: string;
	description?: string;
	name: string;
	type: EPAMTargetType;
	/** @format double */
	id: number;
}

export interface PagedResultPAMTargetDetailModel {
	/** @format double */
	total: number;
	entities: PAMTargetDetailModel[];
}

export type GetPAMTargetDetailsResponseDto = PagedResultPAMTargetDetailModel;

export type GetPAMTargetResponseDto = PAMTargetModel;

export type GetPAMTargetDetailResponseDto = PAMTargetDetailModel;

export interface PamSessionDto {
	recordingPath?: string;
	endAt: string | null;
	startAt: string;
	record: string;
	accountName: string;
	userSurname: string;
	userName: string;
	/** @format double */
	userID: number;
	targetName: string;
	targetType: string;
	/** @format double */
	id: number;
}

export interface PagedResultPamSessionDto {
	/** @format double */
	total: number;
	entities: PamSessionDto[];
}

export type GetPAMTargetSessionsResponseDto = PagedResultPamSessionDto;

/**
 * Represents a value that can be of a specified type or null.
 * @format double
 */
export type NullableNumber = number | null;

export interface GetUserResponseDto {
	countryCode?: string | null;
	/** Represents a value that can be of a specified type or null. */
	tenantID: NullableNumber;
	lastLoggedAt?: string;
	createdAt: string;
	status: string;
	phoneNumber?: string | null;
	email: string;
	surname?: string;
	name: string;
	uuid: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetUserResponseDto {
	/** @format double */
	total: number;
	entities: GetUserResponseDto[];
}

export type GetPAMTargetUsersResponseDto = PagedResultGetUserResponseDto;

export interface VerifyPAMTargetResponseDto {
	inRange: boolean;
	reachable: boolean;
}

export interface VerifyPAMTargetRequestDto {
	/** @format double */
	port: number;
	networkAddress: string;
	/** @format double */
	targetID: number | null;
	/** @format double */
	tenantID: number | null;
}

export interface CreatePAMRDPTargetResponseDto {
	/**
	 * ID of the created RDP target
	 * @format double
	 */
	id: number;
}

export interface CreatePAMTargetBase {
	/** Additional arguments that can be passed to the PAM target. */
	additionalArguments: {
		/**
		 * Value for the additional argument.
		 * @example "300"
		 */
		value: string;
		/**
		 * Key for the additional argument.
		 * @example "timeout"
		 */
		key: string;
	}[];
	/** Clipboard settings for the PAM target, specifying copy-paste permissions. */
	clipboard: {
		/**
		 * Whether pasting from the remote target is allowed.
		 * @example false
		 */
		enablePaste: boolean;
		/**
		 * Whether copying to the remote target is allowed.
		 * @example true
		 */
		enableCopy: boolean;
	};
	/** File transfer settings for the PAM target, including upload and download options. */
	fileTransfer: {
		/**
		 * Limit on upload file size in bytes. `null` indicates no limit.
		 * @format double
		 * @example 10485760
		 */
		uploadFileSizeLimit: number | null;
		/**
		 * Root directory for file transfers, if applicable.
		 * @example "/home/user/uploads"
		 */
		rootDirectory?: string;
		/**
		 * Whether file download is enabled.
		 * @example false
		 */
		downloadEnabled: boolean;
		/**
		 * Whether file upload is enabled.
		 * @example true
		 */
		uploadEnabled: boolean;
	};
	/** Settings for session recordings on the PAM target. */
	recording: {
		/**
		 * Retention period in minutes for recordings. `null` indicates unlimited retention.
		 * @example 4320
		 */
		retentionPeriod: NullableNumber;
		/**
		 * Whether recording is enabled for the target.
		 * @example true
		 */
		enabled: boolean;
	};
	/**
	 * Use QR authorization for the PAM target
	 * @example "true"
	 */
	authorizationRequired: boolean;
	/**
	 * Use managed credentials for the PAM target
	 * @example "true"
	 */
	useExcaliburCredentials: boolean;
	/**
	 * Username to use for the PAM target
	 * @example "admin"
	 */
	username: string;
	/**
	 * Port of the PAM target
	 * @format double
	 * @example 3389
	 */
	port: number;
	/**
	 * Hostname of the PAM target
	 * @example "ad-rodc-1.example.com"
	 */
	hostname: string;
	/**
	 * PAM target description
	 * @example "Active Directory Read-Only Domain Controller 1"
	 */
	description?: string;
	/**
	 * PAM target name
	 * @example "AD RODC 1"
	 */
	name: string;
}

export enum ERDPSecurityMode {
	Any = 'any',
	Nla = 'nla',
	NlaExt = 'nla-ext',
	Tls = 'tls',
	Wmconnect = 'wmconnect',
	Rdp = 'rdp',
}

export type CreatePAMRDPTargetRequestDto = CreatePAMTargetBase & {
	/**
	 * Optional configuration for launching a specific remote application via RDP.
	 * When defined, initiates the specified application rather than a full desktop session.
	 */
	remoteApplication?: {
		/**
		 * Command-line arguments for the remote application.
		 * @example "/safe"
		 */
		commandLineArguments: string;
		/**
		 * Working directory where the remote application will be executed.
		 * @example "C:\Program Files\Microsoft Office"
		 */
		workingDirectory: string;
		/**
		 * Name of the remote application to be launched.
		 * @example "Microsoft"
		 */
		name: string;
	};
	/**
	 * Whether to bypass SSL certificate validation.
	 * @example true
	 */
	ignoreCertificate: boolean;
	/** Security mode for the RDP connection, specifying the level of security enforcement. */
	security: ERDPSecurityMode;
	/**
	 * Password for authenticating to the RDP target.
	 * @example "securePassword123!"
	 */
	password: string;
	/**
	 * Username for authenticating to the RDP target.
	 * @example "adminUser"
	 */
	username: string;
	/**
	 * Domain name for RDP authentication.
	 * @example "CORP"
	 */
	domain?: string;
};

/** From T, pick a set of properties whose keys are in the union K */
export interface PickCreatePAMRDPTargetRequestDtoExcludeKeysPassword {
	/**
	 * PAM target name
	 * @example "AD RODC 1"
	 */
	name: string;
	/**
	 * PAM target description
	 * @example "Active Directory Read-Only Domain Controller 1"
	 */
	description?: string;
	/**
	 * Port of the PAM target
	 * @format double
	 * @example 3389
	 */
	port: number;
	/**
	 * Username to use for the PAM target
	 * Username for authenticating to the RDP target.
	 * @example "admin"
	 */
	username: string;
	/** Security mode for the RDP connection, specifying the level of security enforcement. */
	security: ERDPSecurityMode;
	/**
	 * Hostname of the PAM target
	 * @example "ad-rodc-1.example.com"
	 */
	hostname: string;
	/**
	 * Use managed credentials for the PAM target
	 * @example "true"
	 */
	useExcaliburCredentials: boolean;
	/**
	 * Use QR authorization for the PAM target
	 * @example "true"
	 */
	authorizationRequired: boolean;
	/** Settings for session recordings on the PAM target. */
	recording: {
		/** @format double */
		retentionPeriod: number;
		enabled: boolean;
	};
	/** File transfer settings for the PAM target, including upload and download options. */
	fileTransfer: {
		/** @format double */
		uploadFileSizeLimit: number;
		rootDirectory?: string;
		downloadEnabled: boolean;
		uploadEnabled: boolean;
	};
	/** Clipboard settings for the PAM target, specifying copy-paste permissions. */
	clipboard: {
		enablePaste: boolean;
		enableCopy: boolean;
	};
	/** Additional arguments that can be passed to the PAM target. */
	additionalArguments: {
		value: string;
		key: string;
	}[];
	/**
	 * Domain name for RDP authentication.
	 * @example "CORP"
	 */
	domain?: string;
	/**
	 * Whether to bypass SSL certificate validation.
	 * @example true
	 */
	ignoreCertificate: boolean;
	/**
	 * Optional configuration for launching a specific remote application via RDP.
	 * When defined, initiates the specified application rather than a full desktop session.
	 */
	remoteApplication?: {
		commandLineArguments: string;
		workingDirectory: string;
		name: string;
	};
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitCreatePAMRDPTargetRequestDtoPassword = PickCreatePAMRDPTargetRequestDtoExcludeKeysPassword;

export type GetPAMRDPTargetResponseDto = OmitCreatePAMRDPTargetRequestDtoPassword & {
	auditDate?: string;
	isAudit?: boolean;
	/** @format double */
	id: number;
};

export interface UpdatePAMRDPTargetRequestDto {
	/** Additional custom arguments to be passed to the PAM target. */
	additionalArguments?: {
		/**
		 * Value for the additional argument.
		 * @example "300"
		 */
		value?: string;
		/**
		 * Key for the additional argument.
		 * @example "timeout"
		 */
		key?: string;
	}[];
	/** Remote application configuration details. */
	remoteApplication?: {
		/**
		 * Command-line arguments for launching the remote application.
		 * @example "/safe"
		 */
		commandLineArguments?: string;
		/**
		 * Working directory for the remote application.
		 * @example "C:\Program Files\Microsoft Office"
		 */
		workingDirectory?: string;
		/**
		 * Name of the remote application.
		 * @example "Microsoft Word"
		 */
		name?: string;
	};
	/** Clipboard permissions for this target. */
	clipboard?: {
		/**
		 * Enable paste operations from the target to the client.
		 * @example false
		 */
		enablePaste?: boolean;
		/**
		 * Enable copy operations from the client to the target.
		 * @example true
		 */
		enableCopy?: boolean;
	};
	/** File transfer settings, including upload and download permissions and limits. */
	fileTransfer?: {
		/**
		 * Maximum upload file size in bytes. `null` indicates no limit.
		 * @format double
		 * @example 10485760
		 */
		uploadFileSizeLimit?: number | null;
		/**
		 * Enable file downloads from this target.
		 * @example false
		 */
		downloadEnabled?: boolean;
		/**
		 * Enable file uploads for this target.
		 * @example true
		 */
		uploadEnabled?: boolean;
	};
	/** Recording settings, including whether recording is enabled and retention period. */
	recording?: {
		/**
		 * Retention period in days for session recordings. `null` indicates no limit.
		 * @example 30
		 */
		retentionPeriod?: NullableNumber;
		/**
		 * Indicates if session recording is enabled.
		 * @example true
		 */
		enabled?: boolean;
	};
	/**
	 * Password for authentication to the PAM target.
	 * @example "securepassword"
	 */
	password?: string;
	/**
	 * Username for authentication to the PAM target.
	 * @example "adminUser"
	 */
	username?: string;
	/**
	 * The domain for the PAM target, if applicable.
	 * @example "example.com"
	 */
	domain?: string;
	/**
	 * Indicates if QR authorization is required for this PAM target.
	 * @example true
	 */
	authorizationRequired: boolean;
	/**
	 * Indicates if managed credentials should be used for this PAM target.
	 * @example true
	 */
	useExcaliburCredentials: boolean;
	/**
	 * Flag to indicate if SSL certificate validation should be ignored.
	 * @example true
	 */
	ignoreCertificate?: boolean;
	/** Security mode used for RDP connections. */
	security?: ERDPSecurityMode;
	/**
	 * The port number for connecting to the PAM target.
	 * @format double
	 * @example 3389
	 */
	port?: number;
	/**
	 * The hostname of the PAM target.
	 * @example "rdp-target.example.com"
	 */
	hostname?: string;
	/**
	 * Description of the PAM target (optional).
	 * @example "RDP target for Active Directory access"
	 */
	description?: string;
	/**
	 * The name of the PAM target.
	 * @example "AD RDP Target 1"
	 */
	name: string;
}

/** Represents a value that can be of a specified type or null. */
export type NullableString = string | null;

export interface CreatePAMSessionResponseDto {
	/** Represents a value that can be of a specified type or null. */
	sessionID: NullableNumber;
	/** Represents a value that can be of a specified type or null. */
	qr: NullableString;
}

export interface CreatePAMSessionRequestDto {
	/**
	 * First request
	 * @example true
	 */
	first: boolean;
	/**
	 * The height of the window.
	 * @format double
	 * @example 768
	 */
	height: number;
	/**
	 * The width of the window.
	 * @format double
	 * @example 1024
	 */
	width: number;
	/**
	 * The ID of the PAM target to start a session for.
	 * @format double
	 * @example 1
	 */
	targetID: number;
}

/** Make all properties in T optional */
export interface PartialPamSessionDto {
	/** @format double */
	id?: number;
	targetType?: string;
	targetName?: string;
	/** @format double */
	userID?: number;
	userName?: string;
	userSurname?: string;
	accountName?: string;
	record?: string;
	startAt?: string;
	endAt?: string;
	recordingPath?: string;
}

export interface PagedResultPartialPamSessionDto {
	/** @format double */
	total: number;
	entities: PartialPamSessionDto[];
}

export interface PAMUserInputDto {
	endAt: string;
	startAt: string;
	line: string;
	/** @format double */
	sessionID: number;
}

export interface PAMUserInputSessionDto {
	recordingPath?: string;
	userInput: PAMUserInputDto[];
	endAt: string | null;
	startAt: string;
	accountName: string;
	userSurname: string;
	userName: string;
	/** @format double */
	userID: number;
	targetName: string;
	targetType: string;
	/** @format double */
	id: number;
}

export interface PagedResultPAMUserInputSessionDto {
	/** @format double */
	total: number;
	entities: PAMUserInputSessionDto[];
}

export enum EFileTransferType {
	Upload = 'upload',
	Download = 'download',
}

export interface FileTransferDto {
	/** @format double */
	fileSize: number;
	createdAt: string;
	type: EFileTransferType;
	fileExtension: string;
	fileName: string;
	/** @format double */
	id: number;
}

export interface PagedResultFileTransferDto {
	/** @format double */
	total: number;
	entities: FileTransferDto[];
}

export interface PAMTargetInfoDto {
	authorizationRequired: boolean;
	createdAt: string;
	type: EPAMTargetType;
	/** @format double */
	uploadFileSizeLimit?: number;
	fileDownloadEnabled: boolean;
	fileUploadEnabled: boolean;
	clipboardEnablePaste: boolean;
	clipboardEnableCopy: boolean;
	/** Represents a value that can be of a specified type or null. */
	recordingRetentionPeriod: NullableNumber;
	recordingEnabled: boolean;
	username: string;
	/** @format double */
	port: number;
	hostname: string;
	description: string;
	name: string;
	/** @format double */
	sessionID: number;
	/** @format double */
	id: number;
}

export interface CreatePAMSSHTargetResponseDto {
	/**
	 * ID of created SSH target
	 * @format double
	 */
	id: number;
}

export type CreatePAMSSHTargetRequestDto = CreatePAMTargetBase & {
	/**
	 * Passphrase for the private key to successfully authenticate to the SSH target
	 * @example "P4sSW0rD"
	 */
	passphrase?: string;
	/**
	 * Private key to successfully authenticate to the SSH target
	 * @example "-----BEGIN OPENSSH..."
	 */
	privateKey?: string;
	/**
	 * Password to successfully authenticate to the SSH target
	 * @example "P4sSW0rD"
	 */
	password?: string;
};

export type GetPAMSSHTargetResponseDto = CreatePAMTargetBase & {
	auditDate?: string;
	isAudit?: boolean;
	/** @format double */
	id: number;
};

export interface UpdatePAMSSHTargetRequestDto {
	/** Additional arguments for the SSH target (optional) */
	additionalArguments?: {
		value?: string;
		key?: string;
	}[];
	/** Clipboard settings for the SSH target (optional) */
	clipboard?: {
		enablePaste?: boolean;
		enableCopy?: boolean;
	};
	/** File transfer settings for the SSH target (optional) */
	fileTransfer?: {
		/** @format double */
		uploadFileSizeLimit?: number | null;
		rootDirectory?: string;
		downloadEnabled?: boolean;
		uploadEnabled?: boolean;
	};
	/** Settings for recording sessions on the SSH target (optional) */
	recording?: {
		/** Represents a value that can be of a specified type or null. */
		retentionPeriod?: NullableNumber;
		enabled?: boolean;
	};
	/**
	 * Passphrase for the private key, if applicable (optional)
	 * @example "passphrase"
	 */
	passphrase?: string;
	/**
	 * Private key for SSH authentication (optional)
	 * @example "-----BEGIN PRIVATE KEY-----"
	 */
	privateKey?: string;
	/**
	 * Password for accessing the SSH PAM target (optional)
	 * @example "password"
	 */
	password?: string;
	/**
	 * Username for accessing the SSH PAM target (optional)
	 * @example "admin"
	 */
	username?: string;
	/**
	 * Port number for the SSH PAM target (optional)
	 * @format double
	 * @example 22
	 */
	port?: number;
	/**
	 * Hostname of the SSH PAM target (optional)
	 * @example "server1.example.com"
	 */
	hostname?: string;
	/**
	 * Flag indicating if QR authorization is required for access
	 * @example true
	 */
	authorizationRequired: boolean;
	/**
	 * Flag indicating whether managed credentials are used for this target
	 * @example true
	 */
	useExcaliburCredentials: boolean;
	/**
	 * Description of the SSH PAM target (optional)
	 * @example "Primary SSH access to server 1"
	 */
	description?: string;
	/**
	 * Name of the SSH PAM target (optional)
	 * @example "SSH Target 1"
	 */
	name?: string;
}

export interface CreatePAMTargetGroupResponseDto {
	/** @format double */
	id: number;
}

export interface CreatePAMTargetGroupDto {
	borderColor?: string;
	bgColor?: string;
	textColor?: string;
	description?: string;
	name: string;
}

export interface PAMTargetSummaryModel {
	description?: string;
	type: EPAMTargetType;
	name: string;
	/** @format double */
	id: number;
}

export type PAMTargetGroupDetailModel = PAMTargetGroupModel & {
	targets: PAMTargetSummaryModel[];
};

export interface PagedResultPAMTargetGroupDetailModel {
	/** @format double */
	total: number;
	entities: PAMTargetGroupDetailModel[];
}

export type GetPAMTargetGroupDetailsResponseDto = PagedResultPAMTargetGroupDetailModel;

export type GetPAMTargetGroupResponseDto = PAMTargetGroupModel;

export type GetPAMTargetGroupDetailResponseDto = PAMTargetGroupDetailModel;

export interface PagedResultPAMTargetGroupModel {
	/** @format double */
	total: number;
	entities: PAMTargetGroupModel[];
}

export type GetPAMTargetGroupsResponseDto = PagedResultPAMTargetGroupModel;

/** Make all properties in T optional */
export interface PartialCreatePAMTargetGroupDto {
	name?: string;
	description?: string;
	textColor?: string;
	bgColor?: string;
	borderColor?: string;
}

export type UpdatePAMTargetGroupDto = PartialCreatePAMTargetGroupDto;

export interface PermissionGroupModel {
	/** Group permissions */
	permissions: PermissionModel[];
	/** Permission group title */
	title: string;
	/** Permission group description */
	description: string | null;
	/** Permission group name */
	name: string;
	/**
	 * Permission group ID
	 * @format double
	 * @example 1
	 */
	id: number;
}

export interface PagedResultPermissionGroupModel {
	/** @format double */
	total: number;
	entities: PermissionGroupModel[];
}

export type GetPermissionsResponseDto = PagedResultPermissionGroupModel;

export interface CreateRoleResponseDto {
	/**
	 * Role unique identifier
	 * @format double
	 * @example 123
	 */
	id: number;
}

export interface CreateRoleRequestDto {
	permissions: number[];
	description?: string;
	name: string;
}

export interface GetRoleResponseDto {
	permissions: number[];
	isBuiltIn: boolean;
	description?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface GetRoleDetailResponseDto {
	permissions: PermissionModel[];
	isBuiltIn: boolean;
	description?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetRoleResponseDto {
	/** @format double */
	total: number;
	entities: GetRoleResponseDto[];
}

export type GetRolesResponseDto = PagedResultGetRoleResponseDto;

export interface PagedResultGetRoleDetailResponseDto {
	/** @format double */
	total: number;
	entities: GetRoleDetailResponseDto[];
}

export type GetRoleDetailsResponseDto = PagedResultGetRoleDetailResponseDto;

/** Make all properties in T optional */
export interface PartialCreateRoleRequestDto {
	name?: string;
	description?: string;
	permissions?: number[];
}

export type UpdateRoleRequestDto = PartialCreateRoleRequestDto;

export interface InvitationModel {
	/**
	 * Invitation tenant ID
	 * @example 1234
	 */
	tenantID: NullableNumber;
	/** The email address of the user that the invitation is for. */
	email: string;
	/** The unique identifier of the invitation. */
	uuid: string;
	/**
	 * The unique identifier of the user that created the invitation.
	 * @format double
	 * @example 1234
	 */
	createdBy: number;
	/** The date and time when the invitation will expire. */
	expiresAt: string;
	/** The date and time when the invitation was created. */
	createdAt: string;
	/**
	 * The number of failed attempts to accept the invitation.
	 * @format double
	 * @example 0
	 */
	failedAttempts: number;
	/** The status of the invitation. */
	status: EInvitationStatus;
	/**
	 * The unique identifier of the user that the invitation is for.
	 * @format double
	 * @example 5678
	 */
	userID: number;
	/**
	 * Identity store ID.
	 * @format double
	 * @example 1234
	 */
	identityStoreID: number;
	/**
	 * The unique identifier of the invitation.
	 * @format double
	 * @example 1234
	 */
	id: number;
}

export type CreateInvitationResponseDto = InvitationModel[];

export interface CreateInvitationRequestDto {
	userGroupsIDs: number[];
	/** @format double */
	identityStoreID: number;
	uuids: string[];
}

/** Make all properties in T optional */
export interface PartialInvitationModel {
	/**
	 * The unique identifier of the invitation.
	 * @format double
	 * @example 1234
	 */
	id?: number;
	/**
	 * Identity store ID.
	 * @format double
	 * @example 1234
	 */
	identityStoreID?: number;
	/**
	 * The unique identifier of the user that the invitation is for.
	 * @format double
	 * @example 5678
	 */
	userID?: number;
	/** The status of the invitation. */
	status?: EInvitationStatus;
	/**
	 * The number of failed attempts to accept the invitation.
	 * @format double
	 * @example 0
	 */
	failedAttempts?: number;
	/** The date and time when the invitation was created. */
	createdAt?: string;
	/** The date and time when the invitation will expire. */
	expiresAt?: string;
	/**
	 * The unique identifier of the user that created the invitation.
	 * @format double
	 * @example 1234
	 */
	createdBy?: number;
	/** The unique identifier of the invitation. */
	uuid?: string;
	/** The email address of the user that the invitation is for. */
	email?: string;
	/**
	 * Invitation tenant ID
	 * @format double
	 * @example 1234
	 */
	tenantID?: number;
}

export interface PagedResultPartialInvitationModel {
	/** @format double */
	total: number;
	entities: PartialInvitationModel[];
}

export interface InvitationLinkResponseDto {
	/**
	 * The invitation link.
	 * @example "https://dev.xclbr.local:4443/tenant/minku/register?invitation=Ng%3D%3D"
	 */
	link: string;
}

export interface CreateUsersResponseDto {
	/**
	 * The unique identifiers of the created users.
	 * @example [1234,5678]
	 */
	ids: number[];
}

export interface CreateUserRequestDto {
	countryCode?: string;
	phoneNumber?: string;
	email: string;
	surname?: string;
	name: string;
	uuid?: string;
}

export type CreateUsersRequestDto = CreateUserRequestDto[];

export interface GroupDto {
	description?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface UserDetailModel {
	groups: GroupDto[];
	createdAt: string;
	lastLoggedAt?: string;
	status: string;
	email: string;
	title?: string;
	surname?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultUserDetailModel {
	/** @format double */
	total: number;
	entities: UserDetailModel[];
}

export type GetUserDetailsResponseDto = PagedResultUserDetailModel;

export interface GetMeResponseDto {
	countryCode?: string | null;
	/** Represents a value that can be of a specified type or null. */
	tenantSlug: NullableString;
	/** Represents a value that can be of a specified type or null. */
	tenantID: NullableNumber;
	lastLoggedAt?: string;
	createdAt: string;
	status: string;
	phoneNumber?: string | null;
	email: string;
	surname?: string;
	name: string;
	uuid: string;
	/** @format double */
	id: number;
}

export enum ETenantStatus {
	Pending = 'pending',
	InProgress = 'in_progress',
	Completed = 'completed',
	Failed = 'failed',
}

/** Make all properties in T optional */
export interface PartialTenantModel {
	/**
	 * The id of the tenant
	 * @format double
	 * @example 1234
	 */
	id?: number;
	/** The name of the tenant */
	name?: string;
	/** The alias for the tenant name that is used in the URLs */
	slug?: string;
	/** The description of the tenant */
	description?: string;
	/**
	 * Date the tenant was created
	 * @format date-time
	 */
	createdAt?: string;
	/**
	 * Date the tenant was last updated
	 * @format date-time
	 */
	updatedAt?: string;
	/** The status of the tenant creation process */
	status?: ETenantStatus;
	/** Error message if the tenant creation process failed */
	errorLog?: string;
	/** Whether the tenant is enabled */
	enabled?: boolean;
	/** The IP ranges that the tenant is allowed to access */
	networkAddresses?: string[];
}

export interface PagedResultPartialTenantModel {
	/** @format double */
	total: number;
	entities: PartialTenantModel[];
}

export interface GetUserAccountResponseDto {
	username: string;
	/** @format double */
	id: number;
}

export enum EPermission {
	All = 'all',
	UsersReadAll = 'users_read_all',
	UsersReadSubordinates = 'users_read_subordinates',
	UsersDelete = 'users_delete',
	UsersAuditLogsReadAll = 'users_audit_logs_read_all',
	UsersAuditLogsReadOwn = 'users_audit_logs_read_own',
	UsersAuditLogsReadSubordinates = 'users_audit_logs_read_subordinates',
	InvitationsRead = 'invitations_read',
	InvitationsCreate = 'invitations_create',
	InvitationsUpdate = 'invitations_update',
	InvitationsDelete = 'invitations_delete',
	UserGroupsRead = 'user_groups_read',
	UserGroupsReadOwn = 'user_groups_read_own',
	UserGroupsCreate = 'user_groups_create',
	UserGroupsUpdate = 'user_groups_update',
	UserGroupsDelete = 'user_groups_delete',
	PamTargetsCreate = 'pam_targets_create',
	PamTargetsReadAll = 'pam_targets_read_all',
	PamTargetsReadOwn = 'pam_targets_read_own',
	PamTargetsUpdate = 'pam_targets_update',
	PamTargetsDelete = 'pam_targets_delete',
	PamSessionsCreate = 'pam_sessions_create',
	PamSessionsReadAll = 'pam_sessions_read_all',
	PamSessionsReadOwn = 'pam_sessions_read_own',
	PamRecordingsRead = 'pam_recordings_read',
	PamGroupsCreate = 'pam_groups_create',
	PamGroupsRead = 'pam_groups_read',
	PamGroupsUpdate = 'pam_groups_update',
	PamGroupsDelete = 'pam_groups_delete',
	PamTargetAuditLogsReadAll = 'pam_target_audit_logs_read_all',
	PamTargetAuditLogsReadOwn = 'pam_target_audit_logs_read_own',
	PamTextSearchReadAll = 'pam_text_search_read_all',
	PamTextSearchReadOwn = 'pam_text_search_read_own',
	IdentityStoresRead = 'identity_stores_read',
	IdentityStoresCreate = 'identity_stores_create',
	IdentityStoresUpdate = 'identity_stores_update',
	IdentityStoresDelete = 'identity_stores_delete',
	TenantsRead = 'tenants_read',
	TenantsCreate = 'tenants_create',
	TenantsUpdate = 'tenants_update',
	TenantsDelete = 'tenants_delete',
	GeofencesRead = 'geofences_read',
	GeofencesUpdate = 'geofences_update',
	GeofencesCreate = 'geofences_create',
	GeofencesDelete = 'geofences_delete',
	OauthClientsCreate = 'oauth_clients_create',
	OauthClientsRead = 'oauth_clients_read',
	OauthClientsUpdate = 'oauth_clients_update',
	OauthClientsDelete = 'oauth_clients_delete',
	RolesCreate = 'roles_create',
	RolesRead = 'roles_read',
	RolesUpdate = 'roles_update',
	RolesDelete = 'roles_delete',
	SecurityPoliciesRead = 'security_policies_read',
	SecurityPoliciesCreate = 'security_policies_create',
	SecurityPoliciesUpdate = 'security_policies_update',
	SecurityPoliciesDelete = 'security_policies_delete',
	SmtpRead = 'smtp_read',
	SmtpCreate = 'smtp_create',
	SmtpUpdate = 'smtp_update',
	SmtpDelete = 'smtp_delete',
	GeofenceGroupsRead = 'geofence_groups_read',
	GeofenceGroupsUpdate = 'geofence_groups_update',
	GeofenceGroupsCreate = 'geofence_groups_create',
	GeofenceGroupsDelete = 'geofence_groups_delete',
	SettingsMapRead = 'settings_map_read',
	SettingsMapUpdate = 'settings_map_update',
	SettingsGeneralRead = 'settings_general_read',
	SettingsGeneralUpdate = 'settings_general_update',
	TokensReadAll = 'tokens_read_all',
	TokensReadOwn = 'tokens_read_own',
	TokensDeleteAll = 'tokens_delete_all',
	TokensDeleteOwn = 'tokens_delete_own',
	ActionsReadAll = 'actions_read_all',
	ActionsReadOwn = 'actions_read_own',
	NetworkPolicyReadAll = 'network_policy_read_all',
	NetworkPolicyDeleteAll = 'network_policy_delete_all',
	NetworkPolicyCreate = 'network_policy_create',
	NetworkPolicyUpdate = 'network_policy_update',
	AccountsReadOwn = 'accounts_read_own',
	AccountsUpdateOwn = 'accounts_update_own',
}

export interface GetMeRoleModelDto {
	tenantPermissions: string[];
	systemPermissions: string[];
}

/** Make all properties in T optional */
export interface PartialGetUserResponseDto {
	/** @format double */
	id?: number;
	uuid?: string;
	name?: string;
	surname?: string;
	email?: string;
	phoneNumber?: string;
	status?: string;
	createdAt?: string;
	lastLoggedAt?: string;
	/** @format double */
	tenantID?: number;
	countryCode?: string;
}

export interface UserGroupDto {
	/** @format date-time */
	updatedAt?: string;
	/** @format date-time */
	createdAt?: string;
	/** @format double */
	totalUsers?: number;
	description?: string;
	name: string;
	isBuiltIn: boolean;
	/** @format double */
	id: number;
}

export interface PagedResultPartialGetUserResponseDto {
	/** @format double */
	total: number;
	entities: PartialGetUserResponseDto[];
}

export type GetUsersResponseDto = PagedResultPartialGetUserResponseDto;

export interface CreateUserGroupResponseDto {
	/** @format double */
	id: number;
}

export interface CreateUserGroupRequestDto {
	description?: string;
	name: string;
}

export interface UpdateUserGroupDto {
	description?: string;
	name?: string;
}

export interface PagedResultUserGroupDto {
	/** @format double */
	total: number;
	entities: UserGroupDto[];
}

export type GetUserGroupResponseDto = PagedResultUserGroupDto;

export interface PhoneNumberDto {
	countryCode: string;
	phoneNumber: string;
	/** @format double */
	id: number;
}

export enum EUserStatus {
	Enabled = 'enabled',
	Verified = 'verified',
	Disabled = 'disabled',
}

export interface UserDetailDto {
	groups: GroupDto[];
	/** @format double */
	certificateID?: number;
	/** @format date-time */
	createdAt: string;
	/** @format date-time */
	lastLoggedAt?: string;
	status: EUserStatus;
	phoneNumberList: PhoneNumberDto[];
	email: string;
	surname?: string;
	title?: string;
	name: string;
	/** @format double */
	tenantID?: number;
	uuid: string;
	/** @format double */
	id: number;
}

export interface PagedResultUserDetailDto {
	/** @format double */
	total: number;
	entities: UserDetailDto[];
}

export type GetUsersInGroupResponseDto = PagedResultUserDetailDto;

export interface UserInGroupModel {
	groups: GroupDto[];
	title?: string;
	email: string;
	surname?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultUserInGroupModel {
	/** @format double */
	total: number;
	entities: UserInGroupModel[];
}

export type GetUserDetailsInGroupResponseDto = PagedResultUserInGroupModel;

export enum ELanguageCode {
	En = 'en',
	Sk = 'sk',
}

export interface ChangeLanguageDto {
	language: ELanguageCode;
}

export interface GetDateResponseDto {
	/**
	 * Timestamp in milliseconds
	 * @format double
	 * @example 1629878400000
	 */
	ts: number;
	/** Date in ISO 8601 format */
	date: string;
}

export enum EAppStatus {
	DEPOYMENT_SETUP = 'DEPOYMENT_SETUP',
	PRODUCTION = 'PRODUCTION',
	DEVELOPMENT = 'DEVELOPMENT',
}

export enum ESetupStep {
	SERVER_SETUP = 'SERVER_SETUP',
	CSR = 'CSR',
	SMTP = 'SMTP',
	SMTP_VERIFY = 'SMTP_VERIFY',
	IDENTITY_STORE = 'IDENTITY_STORE',
	IDENTITY_STORE_AUTHENTICATION = 'IDENTITY_STORE_AUTHENTICATION',
	DEPLOYMENT_FINALIZATION = 'DEPLOYMENT_FINALIZATION',
	SETUP_COMPLETED = 'SETUP_COMPLETED',
}

export interface CreateCSRDto {
	licenseKey: string;
	licenseFile: string;
}

export interface CreateSMTPMailConfigurationDto {
	active?: boolean;
	security: EMailSMTPSecurity;
	email: string;
	sender: string;
	password?: string;
	username?: string;
	/** @format double */
	port: number;
	host: string;
	description?: string;
	name: string;
}

/** Represents a value that can be of a specified type or null. */
export type NullableReadSMTPMailConfigurationResponse = ReadSMTPMailConfigurationResponse | null;

export interface ServerSetupDto {
	hostname: string;
	tokenUrl?: string;
	timezone: string;
}

export interface AuthenticateIdentityStoreDto {
	username: string;
	password: string;
}

export interface TenantModel {
	/** The IP ranges that the tenant is allowed to access */
	networkAddresses?: string[];
	/** Whether the tenant is enabled */
	enabled?: boolean;
	/** Error message if the tenant creation process failed */
	errorLog?: string;
	/** The status of the tenant creation process */
	status: ETenantStatus;
	/**
	 * Date the tenant was last updated
	 * @format date-time
	 */
	updatedAt: string;
	/**
	 * Date the tenant was created
	 * @format date-time
	 */
	createdAt: string;
	/** The description of the tenant */
	description?: string;
	/** The alias for the tenant name that is used in the URLs */
	slug: string;
	/** The name of the tenant */
	name: string;
	/**
	 * The id of the tenant
	 * @format double
	 * @example 1234
	 */
	id: number;
}

export interface CreateTenantResponseDto {
	/**
	 * ID of the created tenant
	 * @format double
	 * @example 12345
	 */
	id: number;
}

export interface CreateTenantRequestDto {
	/** Unique alias for tenant name that can be used in urls */
	slug: string;
	/** Optional list of network addresses associated with the tenant */
	networkAddresses?: string[];
	/** Optional description of the created tenant */
	description?: string;
	/** The name of the tenant to create */
	name: string;
}

export interface UpdateTenantRequestDto {
	/**
	 * An list of network addresses associated with the tenant (optional).
	 * @example ["192.168.1.1","192.168.1.2"]
	 */
	networkAddresses?: string[];
	/**
	 * An description of the tenant to update (optional).
	 * @example "This is a new description for the tenant."
	 */
	description?: string;
	/**
	 * An name of the tenant to update (optional).
	 * @example "New Tenant Name"
	 */
	name?: string;
}

export interface RetryCreateTenantDto {
	/** @format double */
	tenantID: number;
}

export enum ETokenIntegrityStatus {
	Trusted = 'trusted',
	Untrusted = 'untrusted',
}

/** Represents a value that can be of a specified type or null. */
export type NullableETokenIntegrityStatus = ETokenIntegrityStatus | null;

export interface TokenDetailDto {
	/** Represents a value that can be of a specified type or null. */
	integrityStatus: NullableETokenIntegrityStatus;
	createdAt: string;
	systemVersion: string;
	systemName: string;
	model: string;
	brand: string;
	deviceName: string;
	userSurname?: string;
	userName: string;
	userTitle?: string;
	/** @format double */
	userID: number;
	/** @format double */
	id: number;
}

export interface PagedResultTokenDetailDto {
	/** @format double */
	total: number;
	entities: TokenDetailDto[];
}

export type GetTokenDetailResponseDto = PagedResultTokenDetailDto;

/** Make all properties in T optional */
export interface PartialTokenTableRowDto {
	/**
	 * Token ID
	 * @format double
	 */
	id?: number;
	/**
	 * ID of user which token belongs to
	 * @format double
	 */
	userID?: number;
	/** Unique device HW identifier */
	udid?: string;
	/** Token name */
	name?: string;
	/**
	 * Device platform
	 *
	 * (e.g. "Apple", "Samsung", "Xiaomi")
	 */
	brand?: string;
	/**
	 * Device model
	 *
	 * (e.g. "iPhone 12 Pro", "Galaxy S21")
	 */
	model?: string;
	/**
	 * Device OS name
	 *
	 * (e.g. "iOS", "Android")
	 */
	systemName?: string;
	/**
	 * Device OS version
	 *
	 * (e.g. "14.5", "11.0")
	 */
	systemVersion?: string;
	/** Token application version */
	appVersion?: string;
	/** Token PushID */
	pushID?: string;
	/** Device certificate for signature verification */
	certificate?: string;
	/**
	 * Date-time when token was last connected.
	 * ISO 8601 format
	 */
	lastConnectedAt?: string;
	/**
	 * Date-time when token entity was created.
	 * ISO 8601 format
	 */
	createdAt?: string;
	/** Token integrity status */
	integrityStatus?: ETokenIntegrityStatus;
}

/** Make all properties in T optional */
export interface PartialTokenModel {
	/**
	 * Token ID
	 * @format double
	 */
	id?: number;
	/**
	 * ID of user which token belongs to
	 * @format double
	 */
	userID?: number;
	/** Unique device HW identifier */
	uid?: string;
	/** Token name */
	name?: string;
	/** Token platform */
	platform?: string;
	/** System */
	system?: string;
	/** Token application version */
	version?: string;
	/**
	 * Date-time when token was last connected.
	 * ISO 8601 format
	 */
	lastConnectedAt?: string;
	/**
	 * Date-time when token entity was created.
	 * ISO 8601 format
	 */
	createdAt?: string;
}

export interface PagedResultPartialTokenModel {
	/** @format double */
	total: number;
	entities: PartialTokenModel[];
}

export interface TokenVersionsDto {
	android: Record<string, number>;
	ios: Record<string, number>;
	windows: Record<string, number>;
	ipados: Record<string, number>;
}

export type TokenSystemVersionsDto = TokenVersionsDto;

export interface DeviceCountDto {
	/** @format double */
	total: number;
	/** @format double */
	offline: number;
	/** @format double */
	online: number;
}

export type TokenCountDto = DeviceCountDto;

export interface CreateSecurityPolicyResponseDto {
	/** @format double */
	id: number;
}

export interface CreateSecurityPolicyRequestDto {
	ruleSets: number[];
	pamTargetGroups: number[];
	userGroups: number[];
	description?: string;
	name: string;
}

export interface SecurityPolicyModel {
	/** Represents a value that can be of a specified type or null. */
	tenantID: NullableNumber;
	description?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultSecurityPolicyModel {
	/** @format double */
	total: number;
	entities: SecurityPolicyModel[];
}

export type GetSecurityPoliciesResponseDto = PagedResultSecurityPolicyModel;

export interface PagedResultGroupDto {
	/** @format double */
	total: number;
	entities: GroupDto[];
}

export type GetGroupResponseDto = PagedResultGroupDto;

export interface RuleSetGeoModel {
	/** @format double */
	radius: number;
	/** @format double */
	longitude: number;
	/** @format double */
	latitude: number;
	name: string;
	/** @format double */
	id: number;
}

export interface RuleSetGeoGroupModel {
	geofences: RuleSetGeoModel[];
	name: string;
	/** @format double */
	id: number;
}

export interface GetRuleSetResponseDto {
	integrityCheck: boolean;
	/** @format double */
	securityPoliciesAllCount: number;
	securityPolicies: {
		/** @format double */
		id: number;
		name: string;
	}[];
	/** @format double */
	tenantID: number | null;
	timeRange?: {
		end: string;
		start: string;
	};
	timeRanges: {
		to: string;
		from: string;
	}[];
	ips: string[];
	phoneConnectionStatuses: string[];
	phoneConnectionIPs: {
		to: string;
		from: string;
	}[];
	days: string[];
	name: string;
	geofences: RuleSetGeoModel[][];
	geofenceGroups: RuleSetGeoGroupModel[][];
	factors: string[][];
	description: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetRuleSetResponseDto {
	/** @format double */
	total: number;
	entities: GetRuleSetResponseDto[];
}

export type GetDetailedRuleSetsResponseDto = PagedResultGetRuleSetResponseDto;

export interface CreateRuleSetRequestDto {
	integrityCheck: boolean;
	timeRange?: {
		end: string;
		start: string;
	};
	timeRanges: {
		to: string;
		from: string;
	}[];
	phoneConnectionStatuses: string[];
	ips: string[];
	phoneConnectionIPs: {
		to: string;
		from: string;
	}[];
	days: string[];
	name: string;
	geofences: number[][];
	geofenceGroups: number[][];
	factors: string[][];
	description: string;
}

export interface UpdateRuleSetRequestDto {
	integrityCheck: boolean;
	timeRange?: {
		end: string;
		start: string;
	};
	timeRanges: {
		to: string;
		from: string;
	}[];
	phoneConnectionStatuses: string[];
	ips: string[];
	phoneConnectionIPs: {
		to: string;
		from: string;
	}[];
	days: string[];
	name: string;
	geofences: number[][];
	geofenceGroups: number[][];
	factors: string[][];
	description: string;
}

export interface RuleSetSecurityPolicyModel {
	name: string;
	/** @format double */
	id: number;
}

export interface GetRuleSetCollectionDto {
	/** @format double */
	securityPoliciesAllCount: number;
	securityPolicies: RuleSetSecurityPolicyModel[];
	/** @format double */
	tenantID: number | null;
	description: string;
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetRuleSetCollectionDto {
	/** @format double */
	total: number;
	entities: GetRuleSetCollectionDto[];
}

export interface UpdateSystemPropertiesDto {
	hostname?: string;
	tokenUrl?: string;
	timezone?: string;
	googleKey?: string;
	enabledPlacesApi?: boolean;
	k8sOAuthUrl?: string;
	k8sOAuthUsername?: string;
	k8sOAuthPassword?: string;
	k8sAPIUrl?: string;
	k8sAPINamespace?: string;
}

/** Represents a value that can be of a specified type or null. */
export type NullableBoolean = boolean | null;

export interface SystemPropertiesResponseDto {
	/** Represents a value that can be of a specified type or null. */
	k8sAPINamespace: NullableString;
	/** Represents a value that can be of a specified type or null. */
	k8sAPIUrl: NullableString;
	/** Represents a value that can be of a specified type or null. */
	k8sOAuthUsername: NullableString;
	/** Represents a value that can be of a specified type or null. */
	k8sOAuthUrl: NullableString;
	/** Represents a value that can be of a specified type or null. */
	enabledPlacesApi: NullableBoolean;
	/** Represents a value that can be of a specified type or null. */
	googleApiKey: NullableString;
	timezone: string;
	/** Represents a value that can be of a specified type or null. */
	tokenUrl: NullableString;
	/** Represents a value that can be of a specified type or null. */
	hostname: NullableString;
}

export interface UpdateMapSettingsPropertiesDto {
	googleKey?: string;
	enabledPlacesApi?: boolean;
}

export interface MapSettingsPropertiesResponseDto {
	googleMapsdefaultApiKey: string;
	/** Represents a value that can be of a specified type or null. */
	enabledPlacesApi: NullableBoolean;
	/** Represents a value that can be of a specified type or null. */
	googleApiKey: NullableString;
}

export type InvitationExpirationValuesResponseDto = {
	/** @format double */
	value: number;
	key: string;
}[];

export type UpdateInvitationExpirationValuesResponseDto = {
	key: string;
}[];

export type UpdateInvitationExpirationValuesDto = {
	/** @format double */
	value: number;
	key: string;
}[];

export interface GetDeploymentCertificateDto {
	content: string;
	fingerprint: string;
	signatureAlgorithm: string;
	validity: {
		/** @format date-time */
		notAfter: string;
		/** @format date-time */
		notBefore: string;
	};
	subject: string;
	issuer: string;
}

export interface GetVersionsResponseDto {
	token: string | null;
	repository: string | null;
	pamOrchestrator: string | null;
	mailer: string | null;
	identityStore: string | null;
	hsm: string | null;
	core: string | null;
	ca: string | null;
	api: string | null;
}

export enum ETenantResourceType {
	Deployment = 'deployment',
	Service = 'service',
	AutoScaler = 'autoScaler',
	NetworkPolicy = 'networkPolicy',
	ConfigMap = 'configMap',
}

export interface TenantResourceDto {
	/** Log of errors related to the resource, can be null */
	errorLog: string | null;
	/** Timestamp when the resource was created */
	createdAt: string;
	/**
	 * ID of the tenant associated with the resource, can be null
	 * @format double
	 */
	tenantID: number | null;
	/** Status of the tenant resource */
	status: ETenantStatus;
	/** Type of the tenant resource */
	type: ETenantResourceType;
	/** Name of the tenant resource */
	name: string;
	/**
	 * Unique identifier for the tenant resource
	 * @format double
	 */
	id: number;
}

export interface PagedResultTenantResourceDto {
	/** @format double */
	total: number;
	entities: TenantResourceDto[];
}

export type GetTenantResourcesResponseDto = PagedResultTenantResourceDto;

export enum EActionType {
	Registration = 'registration',
	Authentication = 'authentication',
}

export interface GetActionCollectionEntityDto {
	pamTargetType?: string;
	/** @format double */
	targetID: number;
	status: string;
	/** @format date-time */
	createdAt: string;
	/** Represents a value that can be of a specified type or null. */
	geofenceName: NullableString;
	targetName: string;
	/** @format double */
	targetType: number;
	accountName: string;
	userSurname: string;
	userName: string;
	userTitle?: string;
	action: EActionType;
	/** @format double */
	id: number;
}

export interface PagedResultGetActionCollectionEntityDto {
	/** @format double */
	total: number;
	entities: GetActionCollectionEntityDto[];
}

export interface TokenTableRowDto {
	/**
	 * Token ID
	 * @format double
	 */
	id: number;
	/**
	 * ID of user which token belongs to
	 * @format double
	 */
	userID: number;
	/** Unique device HW identifier */
	udid: string;
	/** Token name */
	name: string;
	/**
	 * Device platform
	 *
	 * (e.g. "Apple", "Samsung", "Xiaomi")
	 */
	brand: string;
	/**
	 * Device model
	 *
	 * (e.g. "iPhone 12 Pro", "Galaxy S21")
	 */
	model: string;
	/**
	 * Device OS name
	 *
	 * (e.g. "iOS", "Android")
	 */
	systemName: string;
	/**
	 * Device OS version
	 *
	 * (e.g. "14.5", "11.0")
	 */
	systemVersion: string;
	/** Token application version */
	appVersion: string;
	/** Token PushID */
	pushID: string;
	/** Device certificate for signature verification */
	certificate: string;
	/**
	 * Date-time when token was last connected.
	 * ISO 8601 format
	 */
	lastConnectedAt: string;
	/**
	 * Date-time when token entity was created.
	 * ISO 8601 format
	 */
	createdAt: string;
	/** Token integrity status */
	integrityStatus: NullableETokenIntegrityStatus;
}

/** Represents a value that can be of a specified type or null. */
export type NullableGeofenceModel = GeofenceModel | null;

export interface GetActionDetailDto {
	/** Represents a value that can be of a specified type or null. */
	radius: NullableNumber;
	/** Represents a value that can be of a specified type or null. */
	longitude: NullableNumber;
	/** Represents a value that can be of a specified type or null. */
	latitude: NullableNumber;
	/** Represents a value that can be of a specified type or null. */
	geofence: NullableGeofenceModel;
	pamTargetType?: string;
	factors: string[];
	userGroups?: string;
	phoneIP: string;
	phoneConnectionStatus: string;
	/** @format date-time */
	createdAt: string;
	status: string;
	token: TokenTableRowDto;
	targetName: string;
	/** @format double */
	targetID: number;
	/** @format double */
	targetType: number;
	accountName: string;
	userSurname: string;
	userName: string;
	useTitle?: string;
	action: EActionType;
	/** @format double */
	userID: number;
	/** @format double */
	id: number;
}

export interface GetActionResultDto {
	/** @format date-time */
	createdAt: string;
	value: string;
	result: string;
	resultType: string;
	/** @format double */
	id: number;
}

/** DTO for creating a network policy. */
export interface CreateNetworkPolicyDto {
	/**
	 * List of network addresses.
	 * @example ["127.1.1.8"]
	 */
	networkAddresses: string[];
}

/** Represents a model for PAM IP Range. */
export interface PAMIPRangeModel {
	/** IP range. */
	networkAddress: string;
	/** The identifier for the tenant, can be null for system tenant. */
	tenantID: NullableNumber;
	/**
	 * The unique identifier for the IP range.
	 * @format double
	 */
	id: number;
}

export interface PagedResultPAMIPRangeModel {
	/** @format double */
	total: number;
	entities: PAMIPRangeModel[];
}

export type GetNetworkPoliciesResponseDto = PagedResultPAMIPRangeModel;

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '/api/v1' });
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === 'object' && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<AxiosResponse<T>> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
			body = JSON.stringify(body);
		}

		return this.instance.request({
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
			},
			params: query,
			responseType: responseFormat,
			data: body,
			url: path,
		});
	};
}

/**
 * @title @excalibur-enterprise/excalibur-v4-api
 * @version 4.2.0
 * @license MIT
 * @baseUrl /api/v1
 * @contact Excalibur s.r.o.
 *
 * The Excalibur API service is a dedicated backend service designed to provide RESTful API endpoints for the Excalibur application. It handles tasks such as request serving, data validation, and authentication. While it doesn't contain the core business logic, it serves as a crucial link between the client applications and the underlying data models. The service is built with a focus on performance, security, and scalability, ensuring efficient and secure data exchange. It also includes features for error handling and logging, providing valuable insights for debugging and monitoring.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	auth = {
		/**
		 * @description Get user invitation details
		 *
		 * @tags Authentication
		 * @name GetUserInvitationDetail
		 * @summary Retrieves the details of a user invitation by encoded invitation.
		 * @request GET:/auth/invitation/{invitation}
		 */
		getUserInvitationDetail: (invitation: string, params: RequestParams = {}) =>
			this.request<UserInvitationDetailDto, void>({
				path: `/auth/invitation/${invitation}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Register a new user
		 *
		 * @tags Authentication
		 * @name Register
		 * @summary Registers a new user based on the provided details.
		 * @request POST:/auth/register
		 */
		register: (data: RegisterRequestDto, params: RequestParams = {}) =>
			this.request<RegisterResponseDto, void>({
				path: `/auth/register`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get session details
		 *
		 * @tags Authentication
		 * @name Session
		 * @summary Retrieves the maximum session age configuration.
		 * @request GET:/auth/session
		 * @secure
		 */
		session: (params: RequestParams = {}) =>
			this.request<SessionMaxAgeModel, void>({
				path: `/auth/session`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Login user
		 *
		 * @tags Authentication
		 * @name Login
		 * @summary Logs in a user using the provided login credentials.
		 * @request POST:/auth/login
		 */
		login: (data: LoginRequestDto, params: RequestParams = {}) =>
			this.request<LoginResponseDto, void>({
				path: `/auth/login`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Check if user is registered
		 *
		 * @tags Authentication
		 * @name Registered
		 * @summary Checks if the user is already registered based on session info.
		 * @request GET:/auth/registered
		 */
		registered: (params: RequestParams = {}) =>
			this.request<
				{
					registered: boolean;
				},
				void
			>({
				path: `/auth/registered`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Refresh access token
		 *
		 * @tags Authentication
		 * @name RefreshToken
		 * @summary Refreshes the access token based on the provided refresh token.
		 * @request POST:/auth/refresh
		 */
		refreshToken: (params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/auth/refresh`,
				method: 'POST',
				...params,
			}),

		/**
		 * @description Logout user
		 *
		 * @tags Authentication
		 * @name Logout
		 * @summary Logs out the user by clearing authentication cookies.
		 * @request POST:/auth/logout
		 * @secure
		 */
		logout: (params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/auth/logout`,
				method: 'POST',
				secure: true,
				...params,
			}),
	};
	authorize = {
		/**
		 * No description
		 *
		 * @tags Authorization
		 * @name Authorize
		 * @request GET:/authorize
		 * @secure
		 */
		authorize: (params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/authorize`,
				method: 'GET',
				secure: true,
				...params,
			}),
	};
	geofences = {
		/**
		 * @description Get geofences in system scope
		 *
		 * @tags Geofences
		 * @name GetSystemGeofences
		 * @summary Get system geofences
		 * @request GET:/geofences/system
		 * @secure
		 */
		getSystemGeofences: (
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetGeofencesResponseDto, void>({
				path: `/geofences/system`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create a new geofence based on information provided.
		 *
		 * @tags Geofences
		 * @name CreateGeofence
		 * @summary Create a new geofence
		 * @request POST:/geofences
		 * @secure
		 */
		createGeofence: (data: CreateGeofenceRequestDto, params: RequestParams = {}) =>
			this.request<CreateGeofenceResponseDto, any>({
				path: `/geofences`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get geofences
		 *
		 * @tags Geofences
		 * @name GetGeofences
		 * @summary Get geofences
		 * @request GET:/geofences
		 * @secure
		 */
		getGeofences: (
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetGeofencesResponseDto, void>({
				path: `/geofences`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete multiple geofences by their IDs
		 *
		 * @tags Geofences
		 * @name DeleteGeofences
		 * @summary Delete multiple geofences by their IDs
		 * @request DELETE:/geofences
		 * @secure
		 */
		deleteGeofences: (
			query: {
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/geofences`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve geofence information by ID
		 *
		 * @tags Geofences
		 * @name GetGeofence
		 * @summary Get geofence by ID
		 * @request GET:/geofences/{geofenceID}
		 * @secure
		 */
		getGeofence: (geofenceId: number, params: RequestParams = {}) =>
			this.request<GetGeofenceResponseDto, void>({
				path: `/geofences/${geofenceId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update a geofence based on provided ID and information.
		 *
		 * @tags Geofences
		 * @name UpdateGeofence
		 * @summary Update a geofence
		 * @request PATCH:/geofences/{geofenceID}
		 * @secure
		 */
		updateGeofence: (geofenceId: number, data: UpdateGeofenceRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/geofences/${geofenceId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete a geofence by ID
		 *
		 * @tags Geofences
		 * @name DeleteGeofence
		 * @summary Delete a geofence by ID
		 * @request DELETE:/geofences/{geofenceID}
		 * @secure
		 */
		deleteGeofence: (geofenceId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/geofences/${geofenceId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Get geofence groups by geofence ID
		 *
		 * @tags Geofence Groups
		 * @name GetGeofenceGroupsByGeofenceId
		 * @summary Get geofence groups by geofence ID
		 * @request GET:/geofences/{geofenceID}/groups
		 * @secure
		 */
		getGeofenceGroupsByGeofenceId: (geofenceId: number, params: RequestParams = {}) =>
			this.request<number[], void>({
				path: `/geofences/${geofenceId}/groups`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get geofence type collection
		 *
		 * @tags Geofence Types
		 * @name ReadGeofenceTypes
		 * @summary Get geofence type collection
		 * @request GET:/geofences/types
		 * @secure
		 */
		readGeofenceTypes: (
			query?: {
				/**
				 * The filter to apply
				 * @default []
				 */
				filter?: string[];
				/**
				 * The sort order to apply
				 * @default []
				 */
				sort?: string[];
				/**
				 * The columns to return
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of items to take
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialGeofenceTypeDto, void>({
				path: `/geofences/types`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve geofence type information by ID
		 *
		 * @tags Geofence Types
		 * @name ReadGeofenceType
		 * @summary Get geofence type by ID
		 * @request GET:/geofences/types/{geofenceTypeID}
		 * @secure
		 */
		readGeofenceType: (geofenceTypeId: number, params: RequestParams = {}) =>
			this.request<PartialGeofenceTypeDto, void>({
				path: `/geofences/types/${geofenceTypeId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	geofenceGroups = {
		/**
		 * @description Create a new geofence group based on information provided. The group can be created with or without geofences assigned to it.
		 *
		 * @tags Geofence Groups
		 * @name CreateGeofenceGroup
		 * @summary Create a new geofence group
		 * @request POST:/geofenceGroups
		 * @secure
		 */
		createGeofenceGroup: (data: CreateGeofenceGroupRequestDto, params: RequestParams = {}) =>
			this.request<CreateGeofenceGroupResponseDto, void>({
				path: `/geofenceGroups`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete a geofence groups by IDs
		 *
		 * @tags Geofence Groups
		 * @name DeleteGeofenceGroups
		 * @summary Delete a geofence groups by IDs
		 * @request DELETE:/geofenceGroups
		 * @secure
		 */
		deleteGeofenceGroups: (
			query: {
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/geofenceGroups`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Get geofence groups
		 *
		 * @tags Geofence Groups
		 * @name GetGeofenceGroups
		 * @summary Get geofence groups
		 * @request GET:/geofenceGroups
		 * @secure
		 */
		getGeofenceGroups: (
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetGeofenceGroupByIDResponseDto, void>({
				path: `/geofenceGroups`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get system geofence groups
		 *
		 * @tags Geofence Groups
		 * @name GetSystemGeofenceGroups
		 * @summary Get system geofence groups
		 * @request GET:/geofenceGroups/system
		 * @secure
		 */
		getSystemGeofenceGroups: (
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetGeofenceGroupByIDResponseDto, void>({
				path: `/geofenceGroups/system`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update a geofence group based on provided ID and information.
		 *
		 * @tags Geofence Groups
		 * @name UpdateGeofenceGroup
		 * @summary Update a geofence group
		 * @request PATCH:/geofenceGroups/{groupID}
		 * @secure
		 */
		updateGeofenceGroup: (groupId: number, data: UpdateGeofenceGroupRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/geofenceGroups/${groupId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete a geofence group by ID
		 *
		 * @tags Geofence Groups
		 * @name DeleteGeofenceGroup
		 * @summary Delete a geofence group by ID
		 * @request DELETE:/geofenceGroups/{groupID}
		 * @secure
		 */
		deleteGeofenceGroup: (groupId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/geofenceGroups/${groupId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve geofence group information by ID
		 *
		 * @tags Geofence Groups
		 * @name GetGeofenceGroupById
		 * @summary Get geofence group by ID
		 * @request GET:/geofenceGroups/{groupID}
		 * @secure
		 */
		getGeofenceGroupById: (groupId: number, params: RequestParams = {}) =>
			this.request<GetGeofenceGroupByIDResponseDto, void>({
				path: `/geofenceGroups/${groupId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get detailed information about geofence groups
		 *
		 * @tags Geofence Groups
		 * @name GetDetailedGeofenceGroupCollection
		 * @summary Get geofence group details
		 * @request GET:/geofenceGroups/detail
		 * @secure
		 */
		getDetailedGeofenceGroupCollection: (
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetGeofenceGroupDetailByIDResponseDto, void>({
				path: `/geofenceGroups/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve geofence group detailed information based on provided ID
		 *
		 * @tags Geofence Groups
		 * @name GetGeofenceGroupDetailById
		 * @summary Get geofence group details by ID
		 * @request GET:/geofenceGroups/{groupID}/detail
		 * @secure
		 */
		getGeofenceGroupDetailById: (groupId: number, params: RequestParams = {}) =>
			this.request<GetGeofenceGroupDetailByIDResponseDto, void>({
				path: `/geofenceGroups/${groupId}/detail`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Add geofence to the provided group
		 *
		 * @tags Geofence Groups
		 * @name AddGeofenceToGroup
		 * @summary Add geofence to group
		 * @request POST:/geofenceGroups/{groupID}/geofences/{geofenceID}
		 * @secure
		 */
		addGeofenceToGroup: (groupId: number, geofenceId: number, params: RequestParams = {}) =>
			this.request<number[], void>({
				path: `/geofenceGroups/${groupId}/geofences/${geofenceId}`,
				method: 'POST',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Remove geofence from the provided group by provided ID. The geofence with specified ID will be removed from the group.
		 *
		 * @tags Geofence Groups
		 * @name RemoveGeofenceFromGroup
		 * @summary Remove geofence from group
		 * @request DELETE:/geofenceGroups/{groupID}/geofences/{geofenceID}
		 * @secure
		 */
		removeGeofenceFromGroup: (groupId: number, geofenceId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/geofenceGroups/${groupId}/geofences/${geofenceId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve geofences that are part of the provided group
		 *
		 * @tags Geofence Groups
		 * @name GetGeofencesByGroupId
		 * @summary Get geofences by group ID
		 * @request GET:/geofenceGroups/{groupID}/geofences
		 * @secure
		 */
		getGeofencesByGroupId: (
			groupId: number,
			query?: {
				/**
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * @format double
				 * @default 20
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetGeofenceDetailsInGroupResponseDto, void>({
				path: `/geofenceGroups/${groupId}/geofences`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Set geofences for the provided group
		 *
		 * @tags Geofence Groups
		 * @name SetGeofencesForGroup
		 * @summary Set geofences for group
		 * @request POST:/geofenceGroups/{groupID}/geofences
		 * @secure
		 */
		setGeofencesForGroup: (groupId: number, data: number[], params: RequestParams = {}) =>
			this.request<number[], void>({
				path: `/geofenceGroups/${groupId}/geofences`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Remove geofences from the provided group by provided IDs. The geofences with specified IDs will be removed from the group.
		 *
		 * @tags Geofence Groups
		 * @name RemoveGeofencesFromGroup
		 * @summary Remove geofences from group
		 * @request DELETE:/geofenceGroups/{groupID}/geofences
		 * @secure
		 */
		removeGeofencesFromGroup: (
			groupId: number,
			query: {
				/**
				 * The IDs of the geofences to remove from the group
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/geofenceGroups/${groupId}/geofences`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),
	};
	identityStores = {
		/**
		 * @description Attempts a connection to the provided LDAP Identity Store. Upon successful connection, the identity store details are saved in the database, and its users are synchronized with our system. The response includes the ID of the newly created identity store. If the connection fails due to invalid credentials or other issues, an error is returned, and the identity store is not created.
		 *
		 * @tags Identity Stores
		 * @name CreateIdentityStore
		 * @summary Creates a new identity store by connecting to an LDAP Identity Store.
		 * @request POST:/identityStores
		 * @secure
		 */
		createIdentityStore: (data: CreateIdentityStoreDto, params: RequestParams = {}) =>
			this.request<number, void>({
				path: `/identityStores`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves a paginated list of identity stores with optional filtering, sorting, and column selection.
		 *
		 * @tags Identity Stores
		 * @name GetAllIdentityStores
		 * @summary Retrieve a paginated collection of identity stores.
		 * @request GET:/identityStores
		 * @secure
		 */
		getAllIdentityStores: (
			query?: {
				/**
				 * - An array of strings for filtering the identity stores by specific criteria (optional).
				 * @default []
				 */
				filter?: string[];
				/**
				 * - An array of strings for sorting the identity stores (optional).
				 * @default []
				 */
				sort?: string[];
				/**
				 * - An array of column names to specify which columns to retrieve (optional, default is all columns).
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * - The maximum number of identity stores to return in one page (optional, default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The starting index for pagination (optional, default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultIdentityStoreDto, void>({
				path: `/identityStores`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint allows for the deletion of multiple identity stores specified by their unique IDs. It expects an array of IDs to be provided via the query parameter. If no IDs are provided, a 400 Bad Request error is returned. Upon successful deletion, a 204 No Content response is sent to indicate that the operation was successful and there is no content in the response body.
		 *
		 * @tags Identity Stores
		 * @name DeleteIdentityStores
		 * @summary Delete multiple identity stores by provided IDs
		 * @request DELETE:/identityStores
		 * @secure
		 */
		deleteIdentityStores: (
			query: {
				/** - (array of numbers, required) An array of IDs of the identity stores to delete. */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/identityStores`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve a list of paginated invitations with detailed information for identity store.
		 *
		 * @tags Identity Stores
		 * @name GetDetailedInvitations
		 * @summary Retrieve a list of paginated detailed invitations for identity store
		 * @request GET:/identityStores/{id}/invitations/detail
		 * @secure
		 */
		getDetailedInvitations: (
			id: number,
			query?: {
				/**
				 * An array of strings used for filtering invitations
				 * @default []
				 */
				filter?: string[];
				/**
				 * An array of strings used for sorting invitations
				 * @default []
				 */
				sort?: string[];
				/**
				 * An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The maximum number of invitations to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The offset for paginating through the invitations (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialDetailedInvitationDto, void>({
				path: `/identityStores/${id}/invitations/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Fetches information about a specific identity store by its unique identifier. If the identity store does not exist, a 404 error is returned.
		 *
		 * @tags Identity Stores
		 * @name GetIdentityStore
		 * @summary Get identity store by ID
		 * @request GET:/identityStores/{id}
		 * @secure
		 */
		getIdentityStore: (id: number, params: RequestParams = {}) =>
			this.request<IdentityStoreDto | null, void>({
				path: `/identityStores/${id}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Modifies details of an existing identity store by its ID. Only provided fields in the request body will be updated, allowing for partial updates. If the specified identity store is not found, a 404 error is returned.
		 *
		 * @tags Identity Stores
		 * @name UpdateIdentityStore
		 * @summary Update an existing identity store
		 * @request PATCH:/identityStores/{id}
		 * @secure
		 */
		updateIdentityStore: (id: number, data: UpdateIdentityStoreDto, params: RequestParams = {}) =>
			this.request<RpcUpdateLDAPIdentityStoreResponseAsObject, void>({
				path: `/identityStores/${id}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint allows for the deletion of an identity store specified by its unique ID. If the identity store is not found, a 404 error is returned. Upon successful deletion, a 204 No Content response is sent to indicate that the operation was successful and there is no content in the response body.
		 *
		 * @tags Identity Stores
		 * @name DeleteIdentityStore
		 * @summary Delete an identity store by ID
		 * @request DELETE:/identityStores/{id}
		 * @secure
		 */
		deleteIdentityStore: (id: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/identityStores/${id}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description This endpoint retrieves a list of users associated with a specific identity store identified by its ID. The response includes pagination support through limit and offset parameters, allowing clients to control the number of returned users and the starting point for retrieval. Additionally, filtering, sorting, and specific column selection can be applied to customize the response.
		 *
		 * @tags Identity Stores
		 * @name GetUsers
		 * @summary Get users from specific identity store by Identity Store ID
		 * @request GET:/identityStores/{id}/users
		 * @secure
		 */
		getUsers: (
			id: number,
			query?: {
				/**
				 * - An array of strings for filtering the users by specific criteria (optional).
				 * @default []
				 */
				filter?: string[];
				/**
				 * - The maximum number of users to return in one page (optional, default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The starting index for pagination (optional, default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - An array of strings for sorting the users (optional).
				 * @default []
				 */
				sort?: string[];
				/**
				 * - An array of column names to specify which columns to retriev (optional, default is all columns).
				 * @default ["*"]
				 */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetUsersFromIdentityStoreResponseDto, void>({
				path: `/identityStores/${id}/users`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve a single user from a specific identity store.
		 *
		 * @tags Identity Stores
		 * @name GetUser
		 * @summary Get a user by identity store by identity store ID and username
		 * @request GET:/identityStores/{id}/users/{username}
		 * @secure
		 */
		getUser: (id: number, username: string, params: RequestParams = {}) =>
			this.request<UserFromIdentityStore, void>({
				path: `/identityStores/${id}/users/${username}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	settings = {
		/**
		 * @description Create a SMTP configuration.
		 *
		 * @tags settings
		 * @name CreateSmtpConfiguration
		 * @summary Create a new smtp configuration.
		 * @request POST:/settings/mail/smtp
		 * @secure
		 */
		createSmtpConfiguration: (data: CreateSMTPMailConfigurationRequest, params: RequestParams = {}) =>
			this.request<number, void>({
				path: `/settings/mail/smtp`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get SMTP configuration collection.
		 *
		 * @tags settings
		 * @name ReadSmtpConfigurations
		 * @summary Retrieve SMTP configuration collection
		 * @request GET:/settings/mail/smtp
		 * @secure
		 */
		readSmtpConfigurations: (
			query?: {
				/**
				 * - The offset for paginating through the SMTP configurations (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of SMTP configurations to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<ReadSMTPMailConfigurationResponse[], void>({
				path: `/settings/mail/smtp`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve SMTP configuration by ID
		 *
		 * @tags settings
		 * @name ReadSmtpConfiguration
		 * @summary SMTP configuration by ID.
		 * @request GET:/settings/mail/smtp/{configurationID}
		 * @secure
		 */
		readSmtpConfiguration: (configurationId: number, params: RequestParams = {}) =>
			this.request<ReadSMTPMailConfigurationResponse, void>({
				path: `/settings/mail/smtp/${configurationId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update SMTP configuration by ID
		 *
		 * @tags settings
		 * @name UpdateSmtpConfiguration
		 * @summary Update SMTP configuration by ID.
		 * @request PATCH:/settings/mail/smtp/{configurationID}
		 * @secure
		 */
		updateSmtpConfiguration: (
			configurationId: number,
			data: UpdateSMTPMailConfigurationRequest,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/settings/mail/smtp/${configurationId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Verify SMTP configuration by ID
		 *
		 * @tags settings
		 * @name VerifySmtpConfiguration
		 * @summary Verify SMTP configuration by ID.
		 * @request POST:/settings/mail/smtp/{configurationID}/verify
		 * @secure
		 */
		verifySmtpConfiguration: (
			configurationId: number,
			data: VerifySMTPMailConfigurationDto,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/settings/mail/smtp/${configurationId}/verify`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Activate SMTP configuration by ID
		 *
		 * @tags settings
		 * @name ActivateSmtpConfiguration
		 * @summary Activate SMTP configuration by ID.
		 * @request PATCH:/settings/mail/smtp/{configurationID}/activate
		 * @secure
		 */
		activateSmtpConfiguration: (configurationId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/settings/mail/smtp/${configurationId}/activate`,
				method: 'PATCH',
				secure: true,
				...params,
			}),

		/**
		 * @description Get mail configuration collection.
		 *
		 * @tags settings
		 * @name ReadConfigurations
		 * @summary Create a new geofence group
		 * @request GET:/settings/mail
		 * @secure
		 */
		readConfigurations: (
			query?: {
				/**
				 * - The maximum number to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<ReadMailConfigurationResponse[], void>({
				path: `/settings/mail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve single mail configuration by ID.
		 *
		 * @tags settings
		 * @name ReadConfiguration
		 * @request GET:/settings/mail/{configurationID}
		 * @secure
		 */
		readConfiguration: (configurationId: number, params: RequestParams = {}) =>
			this.request<ReadMailConfigurationResponse, void>({
				path: `/settings/mail/${configurationId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete mail configuration by ID.
		 *
		 * @tags settings
		 * @name DeleteMailConfiguration
		 * @summary Delete mail configuration by provided ID
		 * @request DELETE:/settings/mail/{configurationID}
		 * @secure
		 */
		deleteMailConfiguration: (configurationId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/settings/mail/${configurationId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Delete mail configurations by IDs.
		 *
		 * @tags settings
		 * @name DeleteMailConfigurations
		 * @summary Delete mail configurations by provided IDs
		 * @request DELETE:/settings/mail/smtpIDs
		 * @secure
		 */
		deleteMailConfigurations: (
			query: {
				/**
				 * - The IDs of the mail configurations to delete.
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/settings/mail/smtpIDs`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Update system properties.
		 *
		 * @tags Settings
		 * @name UpdateSystemProperties
		 * @summary Update system properties.
		 * @request PUT:/settings/general
		 * @secure
		 */
		updateSystemProperties: (data: UpdateSystemPropertiesDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/settings/general`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Get system properties
		 *
		 * @tags Settings
		 * @name GetSystemProperties
		 * @summary Get system properties
		 * @request GET:/settings/general
		 * @secure
		 */
		getSystemProperties: (params: RequestParams = {}) =>
			this.request<SystemPropertiesResponseDto, void>({
				path: `/settings/general`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update map settings properties.
		 *
		 * @tags Settings
		 * @name UpdateMapSettingsProperties
		 * @summary Update map settings properties.
		 * @request PUT:/settings/general/map
		 * @secure
		 */
		updateMapSettingsProperties: (data: UpdateMapSettingsPropertiesDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/settings/general/map`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Get Map settings properties
		 *
		 * @tags Settings
		 * @name GetMapSettingsProperties
		 * @summary Get Map settings properties
		 * @request GET:/settings/general/map
		 * @secure
		 */
		getMapSettingsProperties: (params: RequestParams = {}) =>
			this.request<MapSettingsPropertiesResponseDto, void>({
				path: `/settings/general/map`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves the setting for the expiration time of specified invitations by given user
		 *
		 * @tags Settings
		 * @name GetExpirationProperties
		 * @request GET:/settings/general/mail/expirationProperties
		 * @secure
		 */
		getExpirationProperties: (params: RequestParams = {}) =>
			this.request<InvitationExpirationValuesResponseDto, void>({
				path: `/settings/general/mail/expirationProperties`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Updates the expiration properties related to invitations.
		 *
		 * @tags Settings
		 * @name UpdateExpirationProperties
		 * @summary Updates expiration settings for invitations, such as expiration time or policies, based on provided values.
		 * @request PUT:/settings/general/mail/expirationProperties
		 * @secure
		 */
		updateExpirationProperties: (data: UpdateInvitationExpirationValuesDto, params: RequestParams = {}) =>
			this.request<UpdateInvitationExpirationValuesResponseDto, void>({
				path: `/settings/general/mail/expirationProperties`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves the deployment certificate details.
		 *
		 * @tags Settings
		 * @name GetDeploymentCertificate
		 * @summary Fetches the certificate used for deployment, providing details like issuer, subject, validity period, and content.
		 * @request GET:/settings/general/deployment/certificate
		 * @secure
		 */
		getDeploymentCertificate: (params: RequestParams = {}) =>
			this.request<GetDeploymentCertificateDto, void>({
				path: `/settings/general/deployment/certificate`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Downloads the deployment certificate as a PEM file.
		 *
		 * @tags Settings
		 * @name DownloadDeploymentCertificate
		 * @summary Allows the user to download the deployment certificate file in PEM format.
		 * @request GET:/settings/general/deployment/certificate/download
		 * @secure
		 */
		downloadDeploymentCertificate: (params: RequestParams = {}) =>
			this.request<string, void>({
				path: `/settings/general/deployment/certificate/download`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves versions of various deployment components.
		 *
		 * @tags Settings
		 * @name GetVersions
		 * @summary Fetches version of various components such as API, client, and services.
		 * @request GET:/settings/general/versions
		 * @secure
		 */
		getVersions: (params: RequestParams = {}) =>
			this.request<GetVersionsResponseDto, void>({
				path: `/settings/general/versions`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	oauthClient = {
		/**
		 * @description Get a single client by ClientID
		 *
		 * @tags oAuth Client
		 * @name GetOneByClientId
		 * @summary Get a single client by ClientID
		 * @request GET:/oauthClient/{clientID}
		 * @secure
		 */
		getOneByClientId: (clientId: string, params: RequestParams = {}) =>
			this.request<PartialOAuthClientResponseDto, void>({
				path: `/oauthClient/${clientId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update a single OAuth Client by clientID
		 *
		 * @tags oAuth Client
		 * @name UpdateOauthClient
		 * @summary Update a single OAuth Client by clientID
		 * @request PATCH:/oauthClient/{clientID}
		 * @secure
		 */
		updateOauthClient: (clientId: string, data: UpdateOAuthClientDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/oauthClient/${clientId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete a single OAuth Client by clientID
		 *
		 * @tags oAuth Client
		 * @name DeleteOauthClient
		 * @summary Delete a single OAuth Client by clientID
		 * @request DELETE:/oauthClient/{clientID}
		 * @secure
		 */
		deleteOauthClient: (clientId: string, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/oauthClient/${clientId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Get paginated OAuth clients collection
		 *
		 * @tags oAuth Client
		 * @name GetOAuthClientsCollection
		 * @summary Get paginated OAuth clients collection
		 * @request GET:/oauthClient
		 * @secure
		 */
		getOAuthClientsCollection: (
			query?: {
				/**
				 * - An array of strings used for filtering OAuth clients
				 * @default []
				 */
				filter?: string[];
				/**
				 * - An array of strings used for sorting OAuth clients
				 * @default []
				 */
				sort?: string[];
				/**
				 * - An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * - The maximum number of OAuth clients to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating through the OAuth clients (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialOAuthClientsCollectionResponseDto, void>({
				path: `/oauthClient`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create new OAuth Client
		 *
		 * @tags oAuth Client
		 * @name CreateOauthClient
		 * @summary Create new OAuth Client
		 * @request POST:/oauthClient
		 * @secure
		 */
		createOauthClient: (data: CreateOAuthClientDto, params: RequestParams = {}) =>
			this.request<PartialCreateOAuthClientResponseDto, void>({
				path: `/oauthClient`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete multiple OAuth Clients by clientIDs
		 *
		 * @tags oAuth Client
		 * @name DeleteOauthClients
		 * @summary Delete multiple OAuth Clients by clientIDs
		 * @request DELETE:/oauthClient
		 * @secure
		 */
		deleteOauthClients: (
			query: {
				ids: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/oauthClient`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Refresh OAuth Client secret
		 *
		 * @tags oAuth Client
		 * @name RefreshClientSecret
		 * @summary Refresh OAuth Client secret
		 * @request PATCH:/oauthClient/{clientID}/refresh
		 * @secure
		 */
		refreshClientSecret: (clientId: string, params: RequestParams = {}) =>
			this.request<PartialRefreshClientSecretResponseDto, void>({
				path: `/oauthClient/${clientId}/refresh`,
				method: 'PATCH',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete all permisssions from a single OAuth Client
		 *
		 * @tags oAuth Client
		 * @name DeletePermissionsFromOAuthClient
		 * @summary Delete all OAuth Clients
		 * @request DELETE:/oauthClient/{clientID}/permissions
		 * @secure
		 */
		deletePermissionsFromOAuthClient: (
			clientId: string,
			query?: {
				/** - An array of system permission IDs to delete */
				systemPermissionIDs?: number[];
				/** - An array of tenant permission IDs to delete */
				tenantPermissionIDs?: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/oauthClient/${clientId}/permissions`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Add permissions to a single OAuth Client
		 *
		 * @tags oAuth Client
		 * @name AddPermissionsToOAuthClient
		 * @summary Add permissions to a single OAuth Client
		 * @request POST:/oauthClient/{clientID}/permissions
		 * @secure
		 */
		addPermissionsToOAuthClient: (
			clientId: string,
			data: AddPermissionsToOAuthClintRequestDto,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/oauthClient/${clientId}/permissions`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Get permissions for a single OAuth Client
		 *
		 * @tags oAuth Client
		 * @name GetPermissionsForOAuthClient
		 * @summary Get permissions for a single OAuth Client
		 * @request GET:/oauthClient/{clientID}/permissions
		 * @secure
		 */
		getPermissionsForOAuthClient: (clientId: string, params: RequestParams = {}) =>
			this.request<OAuthClientPermissionsDto, void>({
				path: `/oauthClient/${clientId}/permissions`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create new webhook
		 *
		 * @tags webhook
		 * @name CreateWebhook
		 * @summary Create new webhook
		 * @request POST:/oauthClient/webhook
		 * @secure
		 */
		createWebhook: (data: CreateWebhookRequestDto, params: RequestParams = {}) =>
			this.request<CreateWebhookResponseDto, void>({
				path: `/oauthClient/webhook`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update a webhook
		 *
		 * @tags webhook
		 * @name UpdateWebhook
		 * @summary Update a webhook
		 * @request PATCH:/oauthClient/webhook/{webhookID}
		 * @secure
		 */
		updateWebhook: (webhookId: number, data: UpdateWebhookRequestDto, params: RequestParams = {}) =>
			this.request<UpdateWebhookResponseDto, void>({
				path: `/oauthClient/webhook/${webhookId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete a webhook
		 *
		 * @tags webhook
		 * @name DeleteWebhook
		 * @summary Delete a webhook
		 * @request DELETE:/oauthClient/webhook/{webhookID}
		 * @secure
		 */
		deleteWebhook: (webhookId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/oauthClient/webhook/${webhookId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Get webhook by ID
		 *
		 * @tags webhook
		 * @name GetWebhookById
		 * @summary Get webhook by ID
		 * @request GET:/oauthClient/webhook/{webhookID}
		 * @secure
		 */
		getWebhookById: (webhookId: number, params: RequestParams = {}) =>
			this.request<GetWebhookByIdResponseDto, void>({
				path: `/oauthClient/webhook/${webhookId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get webhooks belonging to an OAuth client optionally filtering by event type
		 *
		 * @tags webhook
		 * @name GetWebhooksByOauthClient
		 * @summary Get webhooks belonging to an OAuth client optionally filtering by event type
		 * @request GET:/oauthClient/{oAuthClientID}/webhooks
		 * @secure
		 */
		getWebhooksByOauthClient: (
			oAuthClientId: string,
			query?: {
				/** - Optional name of the event type to filter by */
				eventType?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<GetWebhooksByOauthClientResponseDto, void>({
				path: `/oauthClient/${oAuthClientId}/webhooks`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Sends testing event to the webhook
		 *
		 * @tags webhook
		 * @name SendTestWebhookEvent
		 * @summary Sends testing event to the webhook
		 * @request POST:/oauthClient/webhook/{webhookID}/test
		 * @secure
		 */
		sendTestWebhookEvent: (webhookId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/oauthClient/webhook/${webhookId}/test`,
				method: 'POST',
				secure: true,
				...params,
			}),
	};
	oauth = {
		/**
		 * @description Authenticate a client and get an access and refresh token
		 *
		 * @tags oAuth
		 * @name AuthenticateClient
		 * @summary Authenticate a client and get an access and refresh token
		 * @request POST:/oauth/token
		 */
		authenticateClient: (data: OAuthAuthenticateDto, params: RequestParams = {}) =>
			this.request<OAuthTokenResultDto | null, any>({
				path: `/oauth/token`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	webhookEventTypes = {
		/**
		 * @description Get event types
		 *
		 * @tags webhook
		 * @name GetEventTypes
		 * @summary Get event types
		 * @request GET:/webhookEventTypes
		 * @secure
		 */
		getEventTypes: (params: RequestParams = {}) =>
			this.request<GetEventTypesResponseDto, void>({
				path: `/webhookEventTypes`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	pam = {
		/**
		 * @description Get a paginated list of PAM targets. This endpoint retrieves a list of PAM targets, which include resources such as RDP, SSH, and VNC targets. The list can be filtered, sorted, and limited according to the provided query parameters. The response includes a paginated list of targets and metadata about the total number of records. Users with the `PAM_TARGETS_READ_ALL` permission can retrieve all targets, while users with `PAM_TARGETS_READ_OWN` can only retrieve their own targets.
		 *
		 * @tags Privileged Access Management
		 * @name GetTargets
		 * @summary Get a paginated list of PAM targets
		 * @request GET:/pam/targets
		 * @secure
		 */
		getTargets: (
			query?: {
				/**
				 * - The maximum number of records to return; defaults to 20 if not specified (optional).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset of the first record to return; defaults to 0 if not specified (optional).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - An array of sorting criteria to apply to the PAM targets (optional).
				 * @default []
				 */
				sort?: string[];
				/**
				 * - An array of filters to apply to the PAM targets (optional).
				 * @default []
				 */
				filter?: string[];
				/**
				 * - An array of columns to return; defaults to all columns if not specified (optional).
				 * @default ["*"]
				 */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetsResponseDto, void>({
				path: `/pam/targets`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint allows for the deletion of multiple PAM targets based on the provided list of target IDs. The request must include an array of PAM target IDs, and the system will process the deletion of these targets. The deletion will only be permitted for users with the appropriate permissions, and it will be performed on the PAM targets that belong to the tenant specified by the user's context. If the provided target IDs do not correspond to any existing PAM targets, a `404 Not Found` response will be returned. Similarly, if the user does not have sufficient permissions, a `403 Forbidden` or `401 Unauthorized` response will be returned. **Important Notes:** - Deleting a PAM target removes it from the system permanently, so ensure that this operation is executed with caution. - The endpoint supports passing an array of IDs to delete multiple targets in a single request.
		 *
		 * @tags Privileged Access Management
		 * @name DeleteTargets
		 * @summary Delete one or more PAM targets by their IDs.
		 * @request DELETE:/pam/targets
		 * @secure
		 */
		deleteTargets: (
			query: {
				/**
				 * - The list of IDs of the PAM targets to delete.
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/pam/targets`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Duplicate a PAM target in a specific tenant. This endpoint creates a duplicate of an existing PAM target for the specified tenant. The new target will inherit the configuration of the original target and be assigned a unique identifier. Only users with `PAM_TARGETS_CREATE` permission are allowed to duplicate a target.
		 *
		 * @tags Privileged Access Management
		 * @name DuplicatePamTarget
		 * @summary Duplicate a PAM target for a specific tenant
		 * @request POST:/pam/targets/{targetID}/duplicate
		 * @secure
		 */
		duplicatePamTarget: (targetId: number, params: RequestParams = {}) =>
			this.request<DuplicatePAMTargetResponseDto, void>({
				path: `/pam/targets/${targetId}/duplicate`,
				method: 'POST',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get a paginated list of detailed PAM targets. This endpoint retrieves a detailed list of PAM targets, including target information such as type, name, description, hostname, port, and associated groups. The list can be filtered, sorted, and paginated according to the provided query parameters. Users with the `PAM_TARGETS_READ_ALL` permission can retrieve all targets, while users with `PAM_TARGETS_READ_OWN` can only access their own targets.
		 *
		 * @tags Privileged Access Management
		 * @name GetTargetDetails
		 * @summary Retrieve paginated a list of detailed PAM targets
		 * @request GET:/pam/targets/detail
		 * @secure
		 */
		getTargetDetails: (
			query?: {
				/**
				 * - The maximum number of results to return; defaults to 20 if not specified.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The number of results to skip before returning results; defaults to 0 if not specified.
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - An array of sorting criteria to order the results (optional).
				 * @default []
				 */
				sort?: string[];
				/**
				 * - An array of filter criteria to apply to the list of targets (optional).
				 * @default []
				 */
				filter?: string[];
				/**
				 * - An array of columns to retrieve; defaults to all columns if not specified (optional).
				 * @default ["*"]
				 */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetDetailsResponseDto, void>({
				path: `/pam/targets/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get information about a specific PAM target by its unique ID. This endpoint retrieves details for a specific PAM target, identified by its unique ID. The response includes comprehensive information about the target, such as the target type (e.g., RDP or SSH), name, description, hostname, and port. The target's ID must be specified in the request path. The endpoint supports users with `PAM_TARGETS_READ_ALL` permission to access any PAM target, while users with `PAM_TARGETS_READ_OWN` permission can access only their own targets. A **404 Not Found** response will be returned if the target ID does not match any existing records. This endpoint ensures secure access and permissions verification before fetching the requested data.
		 *
		 * @tags Privileged Access Management
		 * @name GetTarget
		 * @summary Retrieve details of a specific PAM target by ID
		 * @request GET:/pam/targets/{targetID}
		 * @secure
		 */
		getTarget: (targetId: number, params: RequestParams = {}) =>
			this.request<GetPAMTargetResponseDto, void>({
				path: `/pam/targets/${targetId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint allows for the deletion of a specific PAM (Privileged Access Management) target based on its unique ID. The deletion will only be performed for the target that corresponds to the provided `targetID`. The user requesting the deletion must have the appropriate permissions to delete PAM targets. If the user does not have the necessary permissions, a `403 Forbidden` or `401 Unauthorized` response will be returned. If the provided `targetID` does not match any existing PAM target in the system, a `404 Not Found` response will be returned, indicating that the target could not be found for deletion. **Important Notes:** - Deleting a PAM target permanently removes it from the system. - Ensure that the deletion of PAM targets is carried out with proper authorization and consideration of potential impacts.
		 *
		 * @tags Privileged Access Management
		 * @name DeleteTarget
		 * @summary Delete a specific PAM target by its ID.
		 * @request DELETE:/pam/targets/{targetID}
		 * @secure
		 */
		deleteTarget: (targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pam/targets/${targetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description This endpoint retrieves the detailed information of a specific PAM (Privileged Access Management) target using its unique ID. The target is identified by its `targetID` and the details returned will vary based on the specific type of target (e.g., RDP, SSH). Users can access this information only if they have the appropriate permissions, such as `PAM_TARGETS_READ_ALL` or `PAM_TARGETS_READ_OWN`. If the target with the provided `targetID` is not found, a `404 Not Found` response will be returned. If the user does not have sufficient permissions to access the target details, a `403 Forbidden` or `401 Unauthorized` response will be returned. **Example Response:** The response includes the detailed information about the specified PAM target, such as its type, description, hostname, and associated groups. **Important Notes:** - Ensure that the correct permissions are granted before accessing PAM target details. - Use the returned details to manage or audit privileged access for the specified target.
		 *
		 * @tags Privileged Access Management
		 * @name GetTargetDetail
		 * @summary Get a specific PAM target detail by its ID.
		 * @request GET:/pam/targets/{targetID}/detail
		 * @secure
		 */
		getTargetDetail: (targetId: number, params: RequestParams = {}) =>
			this.request<GetPAMTargetDetailResponseDto, void>({
				path: `/pam/targets/${targetId}/detail`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve PAM sessions for a specified PAM target based on user permissions. This endpoint retrieves a list of PAM sessions associated with a specific PAM target, identified by `targetID`. Sessions can be filtered, sorted, and paginated to return relevant session details Users with `PAM_SESSIONS_READ_ALL` permission can access all sessions, while users with `PAM_SESSIONS_READ_OWN` permission can only access their own sessions. The endpoint ensures access control based on the permissions attached to the authenticated request. A **404 Not Found** response is returned if the specified target ID does not match any records.
		 *
		 * @tags Privileged Access Management
		 * @name GetPamTargetSessions
		 * @summary Get PAM sessions for a specific PAM target
		 * @request GET:/pam/targets/{targetID}/sessions
		 * @secure
		 */
		getPamTargetSessions: (
			targetId: number,
			query?: {
				/**
				 * - An array of filters to apply to the sessions (optional).
				 * @default []
				 */
				filter?: string[];
				/**
				 * - Sorting criteria to order the sessions (optional).
				 * @default []
				 */
				sort?: string[];
				/**
				 * - Specific columns to include in the result set; defaults to all columns if not specified (optional).
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * - Number of records to skip before starting to return results; defaults to 0 if not specified (optional).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - Maximum number of records to return; defaults to 20 if not specified (optional).
				 * @format double
				 * @default 20
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetSessionsResponseDto, void>({
				path: `/pam/targets/${targetId}/sessions`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve paginated user list with access to a specified PAM target. This endpoint returns a paginated list of users who have access to a specified PAM target, identified by `targetID`. Each user in the response includes details such as user ID, tenant ID, name, surname, email, status, creation timestamp, and a unique identifier (UUID). Only users with the `USERS_READ_ALL` permission can access this data. Filtering, sorting, and pagination options are provided to retrieve the data efficiently. If the specified target ID does not exist, a **404 Not Found** response is returned.
		 *
		 * @tags Privileged Access Management
		 * @name GetPamTargetUsers
		 * @summary Get all users with access to a specified PAM target
		 * @request GET:/pam/targets/{targetID}/users
		 * @secure
		 */
		getPamTargetUsers: (
			targetId: number,
			query?: {
				/**
				 * - Array of filter criteria to refine the list of users returned (optional).
				 * @default []
				 */
				filter?: string[];
				/**
				 * - Array of sorting criteria to order the list of users (optional).
				 * @default []
				 */
				sort?: string[];
				/**
				 * - Specific columns to include in the result set; defaults to all columns if not specified (optional).
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * - Number of records to skip before starting to return results; defaults to 0 if not specified (optional).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - Maximum number of records to return; defaults to 20 if not specified (optional).
				 * @format double
				 * @default 20
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetUsersResponseDto, void>({
				path: `/pam/targets/${targetId}/users`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Deletes a specified PAM (Privileged Access Management) target based on its unique ID. This endpoint allows authorized users to delete a PAM target, identified by its `targetID`. The targetID must belong to an existing PAM target within the tenant associated with the request. If the target does not exist, a **404 Not Found** response is returned. Only users with the `PAM_TARGETS_DELETE` permission can perform this action. Deleting a PAM target removes it from the list of accessible resources within the tenant, meaning users will no longer be able to access or interact with this target.
		 *
		 * @tags Privileged Access Management
		 * @name VerifyPamTarget
		 * @summary Delete a specific PAM target
		 * @request POST:/pam/verify
		 * @secure
		 */
		verifyPamTarget: (data: VerifyPAMTargetRequestDto, params: RequestParams = {}) =>
			this.request<VerifyPAMTargetResponseDto, any>({
				path: `/pam/verify`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint creates a new PAM (Privileged Access Management) RDP target.
		 *
		 * @tags Privileged Access Management (RDP)
		 * @name CreateRdpTarget
		 * @summary Create a new PAM RDP target
		 * @request POST:/pam/targets/rdp
		 * @secure
		 */
		createRdpTarget: (data: CreatePAMRDPTargetRequestDto, params: RequestParams = {}) =>
			this.request<CreatePAMRDPTargetResponseDto, void>({
				path: `/pam/targets/rdp`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint reads a PAM (Privileged Access Management) RDP target.
		 *
		 * @tags Privileged Access Management (RDP)
		 * @name GetRdpTarget
		 * @summary Get a PAM RDP target
		 * @request GET:/pam/targets/rdp/{targetID}
		 * @secure
		 */
		getRdpTarget: (targetId: number, params: RequestParams = {}) =>
			this.request<GetPAMRDPTargetResponseDto, void>({
				path: `/pam/targets/rdp/${targetId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint reads PAM (Privileged Access Management) RDP targets. The result is paginated.
		 *
		 * @tags Privileged Access Management (RDP)
		 * @name UpdateRdpTarget
		 * @summary Get PAM RDP targets
		 * @request PATCH:/pam/targets/rdp/{targetID}
		 * @secure
		 */
		updateRdpTarget: (targetId: number, data: UpdatePAMRDPTargetRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pam/targets/rdp/${targetId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description This endpoint deletes a PAM (Privileged Access Management) RDP target.
		 *
		 * @tags Privileged Access Management (RDP)
		 * @name DeleteRdpTarget
		 * @summary Delete a PAM RDP target
		 * @request DELETE:/pam/targets/rdp/{targetID}
		 * @secure
		 */
		deleteRdpTarget: (targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pam/targets/rdp/${targetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description This endpoint creates a new PAM (Privileged Access Management) session to a target specified by the target ID.
		 *
		 * @tags Privileged Access Management
		 * @name CreatePamSession
		 * @summary Create a new PAM session
		 * @request POST:/pam/sessions
		 * @secure
		 */
		createPamSession: (data: CreatePAMSessionRequestDto, params: RequestParams = {}) =>
			this.request<CreatePAMSessionResponseDto, void>({
				path: `/pam/sessions`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get all PAM sessions.
		 *
		 * @tags Privileged Access Management
		 * @name GetAllPamSessions
		 * @request GET:/pam/sessions
		 * @secure
		 */
		getAllPamSessions: (
			query?: {
				/**
				 * - The maximum number of PAM Sessions to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating through the PAM Sessions (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialPamSessionDto, void>({
				path: `/pam/sessions`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve information about a specific PAM session.
		 *
		 * @tags Privileged Access Management
		 * @name GetPamSessionById
		 * @request GET:/pam/sessions/{sessionID}
		 * @secure
		 */
		getPamSessionById: (sessionId: number, params: RequestParams = {}) =>
			this.request<PartialPamSessionDto | null, void>({
				path: `/pam/sessions/${sessionId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve user inputs for a all PAM sessions.
		 *
		 * @tags Privileged Access Management
		 * @name GetUserInput
		 * @request GET:/pam/sessions/search/{searchTerm}
		 * @secure
		 */
		getUserInput: (
			searchTerm: string,
			query?: {
				/**
				 * - The maximum number of user inputs to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating through the user inputs (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPAMUserInputSessionDto, void>({
				path: `/pam/sessions/search/${searchTerm}`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve file transfers for a specific PAM session.
		 *
		 * @tags Privileged Access Management
		 * @name GetFileTransfers
		 * @request GET:/pam/sessions/{sessionID}/file-transfers
		 * @secure
		 */
		getFileTransfers: (
			sessionId: number,
			query?: {
				/**
				 * - The maximum number of files to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating through the files (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultFileTransferDto, void>({
				path: `/pam/sessions/${sessionId}/file-transfers`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves session recording for a specific PAM session.
		 *
		 * @tags Privileged Access Management
		 * @name GetRecording
		 * @summary Get session recording
		 * @request GET:/pam/sessions/{sessionID}/recording
		 * @secure
		 */
		getRecording: (sessionId: number, params: RequestParams = {}) =>
			this.request<string, void>({
				path: `/pam/sessions/${sessionId}/recording`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve target info for a specific PAM session.
		 *
		 * @tags Privileged Access Management
		 * @name GetPamTargetInfo
		 * @request GET:/pam/sessions/{sessionID}/target
		 * @secure
		 */
		getPamTargetInfo: (sessionId: number, params: RequestParams = {}) =>
			this.request<PAMTargetInfoDto, void>({
				path: `/pam/sessions/${sessionId}/target`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves SSH session typescript.
		 *
		 * @tags Privileged Access Management
		 * @name GetTypescript
		 * @summary Get session recording
		 * @request GET:/pam/sessions/{sessionID}/typescript
		 * @secure
		 */
		getTypescript: (sessionId: number, params: RequestParams = {}) =>
			this.request<string, void>({
				path: `/pam/sessions/${sessionId}/typescript`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint creates a new PAM (Privileged Access Management) SSH target. To successfully create the SSH target, you must provide either a password or a private key. If you choose to provide a private key, an optional passphrase can also be included for added security.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name CreateSshTarget
		 * @summary Create a new PAM SSH target
		 * @request POST:/pam/targets/ssh
		 * @secure
		 */
		createSshTarget: (data: CreatePAMSSHTargetRequestDto, params: RequestParams = {}) =>
			this.request<CreatePAMSSHTargetResponseDto, void>({
				path: `/pam/targets/ssh`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint reads PAM (Privileged Access Management) SSH targets. The result is paginated.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name GetSshTarget
		 * @summary Get PAM SSH targets
		 * @request GET:/pam/targets/ssh/{targetID}
		 * @secure
		 */
		getSshTarget: (targetId: number, params: RequestParams = {}) =>
			this.request<GetPAMSSHTargetResponseDto, void>({
				path: `/pam/targets/ssh/${targetId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint updates a PAM (Privileged Access Management) SSH target.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name UpdateSshTarget
		 * @summary Update a PAM SSH target
		 * @request PATCH:/pam/targets/ssh/{targetID}
		 * @secure
		 */
		updateSshTarget: (targetId: number, data: UpdatePAMSSHTargetRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pam/targets/ssh/${targetId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Retrieves collection of PAM SSH target audit records. Possible to filter, sort, limit and offset the results.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name DeleteRdpTarget2
		 * @request DELETE:/pam/targets/ssh/{targetID}
		 * @originalName deleteRdpTarget
		 * @duplicate
		 * @secure
		 */
		deleteRdpTarget2: (targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pam/targets/ssh/${targetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),
	};
	pamGroups = {
		/**
		 * @description Creates a new PAM target group.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name CreatePamTargetGroup
		 * @summary Create a new PAM target group
		 * @request POST:/pamGroups
		 * @secure
		 */
		createPamTargetGroup: (data: CreatePAMTargetGroupDto, params: RequestParams = {}) =>
			this.request<CreatePAMTargetGroupResponseDto, void>({
				path: `/pamGroups`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves a list of PAM target groups.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetPamTargetGroups
		 * @summary Get a list of PAM target groups
		 * @request GET:/pamGroups
		 * @secure
		 */
		getPamTargetGroups: (
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetGroupsResponseDto, void>({
				path: `/pamGroups`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Deletes multiple PAM target groups by their IDs.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name DeletePamTargetGroups
		 * @summary Delete multiple PAM target groups
		 * @request DELETE:/pamGroups
		 * @secure
		 */
		deletePamTargetGroups: (
			query: {
				/**
				 * The IDs of the PAM target groups to delete.
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/pamGroups`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieves a PAM target group detail by its ID.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetPamTargetGroupDetails
		 * @summary Get a PAM target group detail by ID
		 * @request GET:/pamGroups/detail
		 * @secure
		 */
		getPamTargetGroupDetails: (
			query?: {
				/**
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetGroupDetailsResponseDto, void>({
				path: `/pamGroups/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves a PAM target group by its ID.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetPamTargetGroup
		 * @summary Get a PAM target group by ID
		 * @request GET:/pamGroups/{id}
		 * @secure
		 */
		getPamTargetGroup: (id: number, params: RequestParams = {}) =>
			this.request<GetPAMTargetGroupResponseDto, void>({
				path: `/pamGroups/${id}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Deletes a PAM target group by its ID.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name DeletePamTargetGroup
		 * @summary Delete a PAM target group by ID
		 * @request DELETE:/pamGroups/{id}
		 * @secure
		 */
		deletePamTargetGroup: (id: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pamGroups/${id}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Updates a PAM target group by its ID.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name UpdatePamTargetGroup
		 * @summary Update a PAM target group by ID
		 * @request PATCH:/pamGroups/{id}
		 * @secure
		 */
		updatePamTargetGroup: (id: number, data: UpdatePAMTargetGroupDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pamGroups/${id}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
 * @description Retrieves the details of a specific PAM (Privileged Access Management) target group by its ID.
 *
 * @tags Privileged Access Management (PAM) Groups
 * @name GetPamTargetGroupDetail
 * @summary Fetches detailed information about a PAM target group, including its name, description, colors,
and the associated targets (such as RDP or SSH targets).
 * @request GET:/pamGroups/{id}/detail
 * @secure
 */
		getPamTargetGroupDetail: (id: number, params: RequestParams = {}) =>
			this.request<GetPAMTargetGroupDetailResponseDto, void>({
				path: `/pamGroups/${id}/detail`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Adds a PAM target to a target group.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name AddPamTargetToGroup
		 * @summary Add a PAM target to a group
		 * @request POST:/pamGroups/{id}/targets/{targetID}
		 * @secure
		 */
		addPamTargetToGroup: (id: number, targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pamGroups/${id}/targets/${targetId}`,
				method: 'POST',
				secure: true,
				...params,
			}),

		/**
		 * @description Deletes a PAM target from a target group.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name DeletePamTargetFromGroup
		 * @summary Delete a PAM target from a group
		 * @request DELETE:/pamGroups/{id}/targets/{targetID}
		 * @secure
		 */
		deletePamTargetFromGroup: (id: number, targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pamGroups/${id}/targets/${targetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Adds multiple PAM targets to a target group.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name AddPamTargetsToGroup
		 * @summary Add multiple PAM targets to a group
		 * @request POST:/pamGroups/{id}/targets
		 * @secure
		 */
		addPamTargetsToGroup: (
			id: number,
			query: {
				/**
				 * The IDs of the PAM targets to add.
				 * @example [1,2,3]
				 */
				targetIDs: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/pamGroups/${id}/targets`,
				method: 'POST',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Deletes multiple PAM targets from a target group.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name DeletePamTargetsFromGroup
		 * @summary Delete multiple PAM targets from a group
		 * @request DELETE:/pamGroups/{id}/targets
		 * @secure
		 */
		deletePamTargetsFromGroup: (
			id: number,
			query: {
				/**
				 * The IDs of the PAM targets to delete.
				 * @example [1,2,3]
				 */
				targetIDs: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/pamGroups/${id}/targets`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieves the PAM targets in a target group.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetPamTargetsInGroup
		 * @summary Get PAM targets in a group
		 * @request GET:/pamGroups/{id}/targets
		 * @secure
		 */
		getPamTargetsInGroup: (
			id: number,
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetsResponseDto, void>({
				path: `/pamGroups/${id}/targets`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
 * @description Retrieves detailed information about PAM (Privileged Access Management) targets within a specific target group.
 *
 * @tags Privileged Access Management (PAM) Groups
 * @name GetPamTargetDetailsInGroup
 * @summary Fetches a list of PAM targets that belong to a particular group, including target details such as hostname,
port, authorization requirements, and associated groups.
 * @request GET:/pamGroups/{id}/targets/detail
 * @secure
 */
		getPamTargetDetailsInGroup: (
			id: number,
			query?: {
				/**
				 * - The maximum number of targets to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating results (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - An array of sorting options for ordering the results.
				 * @default []
				 */
				sort?: string[];
				/**
				 * - An array of filtering options to narrow down the target results.
				 * @default []
				 */
				filter?: string[];
				/**
				 * - The specific columns to be included in the response (default is all columns).
				 * @default ["*"]
				 */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetDetailsResponseDto, void>({
				path: `/pamGroups/${id}/targets/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),
	};
	permissions = {
		/**
		 * @description Get all available permissions in groups
		 *
		 * @tags Permissions
		 * @name GetPermissions
		 * @summary Get all available permissions in groups
		 * @request GET:/permissions
		 * @secure
		 */
		getPermissions: (
			query?: {
				/**
				 * The filter to apply to the results. For example, to get only the oauth permissions filter will be ['type=oauth']
				 * @default []
				 */
				filter?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetPermissionsResponseDto, void>({
				path: `/permissions`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),
	};
	roles = {
		/**
		 * @description Create a new user role.
		 *
		 * @tags Roles
		 * @name CreateRole
		 * @summary Create a user role
		 * @request POST:/roles
		 * @secure
		 */
		createRole: (data: CreateRoleRequestDto, params: RequestParams = {}) =>
			this.request<CreateRoleResponseDto, void>({
				path: `/roles`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get user roles collection.
		 *
		 * @tags Roles
		 * @name GetRoles
		 * @summary Get user roles collection
		 * @request GET:/roles
		 * @secure
		 */
		getRoles: (
			query?: {
				/**
				 * - The offset for paginating through the user roles (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of user roles to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetRolesResponseDto, void>({
				path: `/roles`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete multiple user roles by provided IDs
		 *
		 * @tags Roles
		 * @name DeleteRoles
		 * @summary Delete multiple user roles by provided IDs
		 * @request DELETE:/roles
		 * @secure
		 */
		deleteRoles: (
			query: {
				/** - An array of IDs of the user roles to delete */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/roles`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve single role
		 *
		 * @tags Roles
		 * @name GetRole
		 * @summary Retrieve single role by ID
		 * @request GET:/roles/{roleID}
		 * @secure
		 */
		getRole: (roleId: number, params: RequestParams = {}) =>
			this.request<GetRoleResponseDto, void>({
				path: `/roles/${roleId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update an existing user role
		 *
		 * @tags Roles
		 * @name UpdateRole
		 * @summary Update an existing user role
		 * @request PUT:/roles/{roleID}
		 * @secure
		 */
		updateRole: (roleId: number, data: UpdateRoleRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/roles/${roleId}`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete an user role by ID
		 *
		 * @tags Roles
		 * @name DeleteRole
		 * @summary Delete an user role by ID
		 * @request DELETE:/roles/{roleID}
		 * @secure
		 */
		deleteRole: (roleId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/roles/${roleId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve single detailed role with permissions
		 *
		 * @tags Roles
		 * @name GetRoleDetail
		 * @summary Retrieve single detailed role by ID with permissions
		 * @request GET:/roles/{roleID}/detail
		 * @secure
		 */
		getRoleDetail: (roleId: number, params: RequestParams = {}) =>
			this.request<GetRoleDetailResponseDto, void>({
				path: `/roles/${roleId}/detail`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get detailed user roles collection.
		 *
		 * @tags Roles
		 * @name GetRoleDetails
		 * @summary Get detailed user roles collection
		 * @request GET:/roles/detail
		 * @secure
		 */
		getRoleDetails: (
			query?: {
				/**
				 * - The offset for paginating through the user roles (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of user roles to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetRoleDetailsResponseDto, void>({
				path: `/roles/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),
	};
	users = {
		/**
		 * @description This endpoint invites users to join the application by providing their user IDs in the request body. If a user has already been invited, the previous invitation's status will be changed to 'disabled', and a new invitation with the status 'sent' will be created. By default, invitations are valid for 24 hours; after this period, the user cannot use the invitation to register.
		 *
		 * @tags User Invitations
		 * @name CreateInvitations
		 * @summary Invite users to join the application
		 * @request POST:/users/invitations
		 * @secure
		 */
		createInvitations: (data: CreateInvitationRequestDto, params: RequestParams = {}) =>
			this.request<CreateInvitationResponseDto, void>({
				path: `/users/invitations`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description The endpoint retrieves all invitations.
		 *
		 * @tags User Invitations
		 * @name GetInvitations
		 * @summary Get all invitations
		 * @request GET:/users/invitations
		 * @secure
		 */
		getInvitations: (
			query?: {
				/**
				 * An array of strings used for filtering invitations
				 * @default []
				 */
				filter?: string[];
				/**
				 * An array of strings used for sorting invitations
				 * @default []
				 */
				sort?: string[];
				/**
				 * An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The maximum number of invitations to retrieve (default is 20)
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/**
				 * The offset for paginating through the invitations (default is 0)
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialInvitationModel, void>({
				path: `/users/invitations`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description The endpoint deletes multiple invitations by their IDs
		 *
		 * @tags User Invitations
		 * @name DeleteInvitations
		 * @summary Delete multiple invitations
		 * @request DELETE:/users/invitations
		 * @secure
		 */
		deleteInvitations: (
			query: {
				/**
				 * An array of invitation IDs to delete
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/users/invitations`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve a list of paginated invitations with detailed information.
		 *
		 * @tags User Invitations
		 * @name GetDetailedInvitations
		 * @summary Retrieve a list of paginated detailed invitations
		 * @request GET:/users/invitations/detail
		 * @secure
		 */
		getDetailedInvitations: (
			query?: {
				/**
				 * An array of strings used for filtering invitations (optional)
				 * @default []
				 */
				filter?: string[];
				/**
				 * An array of strings used for sorting invitations (optional)
				 * @default []
				 */
				sort?: string[];
				/**
				 * An array of strings representing the columns to retrieve (optional, default is all columns)
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The maximum number of invitations to retrieve (optional, default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The offset for paginating through the invitations (optional, default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialDetailedInvitationDto, void>({
				path: `/users/invitations/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description The endpoint retrieves a specific invitation by its ID.
		 *
		 * @tags User Invitations
		 * @name GetInvitationById
		 * @summary Get a specific invitation
		 * @request GET:/users/invitations/{invitationID}
		 * @secure
		 */
		getInvitationById: (invitationId: number, params: RequestParams = {}) =>
			this.request<PartialInvitationModel, void>({
				path: `/users/invitations/${invitationId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description The endpoint deletes a specific invitation by its ID
		 *
		 * @tags User Invitations
		 * @name DeleteInvitation
		 * @summary Delete a specific invitation
		 * @request DELETE:/users/invitations/{invitationID}
		 * @secure
		 */
		deleteInvitation: (invitationId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/users/invitations/${invitationId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description The endpoint retrieves all invitations for a specific user
		 *
		 * @tags User Invitations
		 * @name GetInvitationsByUserId
		 * @summary Get all invitations for a specific user
		 * @request GET:/users/{userID}/invitations
		 * @secure
		 */
		getInvitationsByUserId: (userId: number, params: RequestParams = {}) =>
			this.request<PartialInvitationModel[], void>({
				path: `/users/${userId}/invitations`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description The endpoint retrieves invitation link by invitation ID
		 *
		 * @tags User Invitations
		 * @name GetInvitationLink
		 * @request GET:/users/invitations/{invitationID}/link
		 * @secure
		 */
		getInvitationLink: (invitationId: number, params: RequestParams = {}) =>
			this.request<InvitationLinkResponseDto, void>({
				path: `/users/invitations/${invitationId}/link`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint creates a new users.
		 *
		 * @tags Users
		 * @name CreateUsers
		 * @summary Create a new user
		 * @request POST:/users
		 * @secure
		 */
		createUsers: (data: CreateUsersRequestDto, params: RequestParams = {}) =>
			this.request<CreateUsersResponseDto, void>({
				path: `/users`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint deletes multiple users by their IDs.
		 *
		 * @tags Users
		 * @name DeleteUsers
		 * @summary Delete multiple users
		 * @request DELETE:/users
		 * @secure
		 */
		deleteUsers: (
			query: {
				/**
				 * The IDs of the users to delete.
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/users`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description This endpoint retrieves a paginated list of users based on the specified query parameters. It supports filtering, sorting, and column selection to tailor the results as needed.
		 *
		 * @tags Users
		 * @name GetUsers
		 * @summary Get multiple users
		 * @request GET:/users
		 * @secure
		 */
		getUsers: (
			query?: {
				/**
				 * The filter to apply to the results (optional).
				 * @default []
				 */
				filter?: string[];
				/**
				 * The sort order to apply to the results (optional).
				 * @default []
				 */
				sort?: string[];
				/**
				 * The columns to retrieve for each result (optional, default is all columns).
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The maximum number of results to retrieve (optional, default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The offset for paginating through the users (optional, default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetUsersResponseDto, void>({
				path: `/users`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves a list of detailed Users.
		 *
		 * @tags Users
		 * @name GetUserDetails
		 * @summary Get a list of detailed Users
		 * @request GET:/users/detail
		 * @secure
		 */
		getUserDetails: (
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetUserDetailsResponseDto, void>({
				path: `/users/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get authenticated user
		 *
		 * @tags Users
		 * @name Me
		 * @summary Get authenticated user data
		 * @request GET:/users/me
		 * @secure
		 */
		me: (params: RequestParams = {}) =>
			this.request<GetMeResponseDto, any>({
				path: `/users/me`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve all tenants related to authenticated user
		 *
		 * @tags Users
		 * @name MeTenants
		 * @summary Get all tenants for authenticated user
		 * @request GET:/users/me/tenants
		 * @secure
		 */
		meTenants: (
			query?: {
				/** @default [] */
				filter?: string[];
				/** @default [] */
				sort?: string[];
				/** @default ["*"] */
				columns?: string[];
				/**
				 * The maximum number of records to return
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The offset of the first record to return
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialTenantModel, void>({
				path: `/users/me/tenants`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve user account for the authenticated user
		 *
		 * @tags Users
		 * @name GetUserAccounts
		 * @summary Get user account for authenticated user
		 * @request GET:/users/me/accounts
		 * @secure
		 */
		getUserAccounts: (params: RequestParams = {}) =>
			this.request<GetUserAccountResponseDto[], void>({
				path: `/users/me/accounts`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update the specified account credentials for the authenticated user
		 *
		 * @tags Users
		 * @name UpdateAccount
		 * @summary Update account credentials for the authenticated user
		 * @request PATCH:/users/me/accounts/{accountID}
		 * @secure
		 */
		updateAccount: (
			accountId: number,
			data: {
				password: string;
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/users/me/accounts/${accountId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Return all permissions for authenticated user
		 *
		 * @tags Users
		 * @name MePermissions
		 * @summary Get all permissions for authenticated user
		 * @request GET:/users/me/permissions
		 * @secure
		 */
		mePermissions: (params: RequestParams = {}) =>
			this.request<EPermission[], void>({
				path: `/users/me/permissions`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get role related to authenticated user
		 *
		 * @tags Users
		 * @name MeRole
		 * @summary Get role for authenticated user
		 * @request GET:/users/me/role
		 * @secure
		 */
		meRole: (params: RequestParams = {}) =>
			this.request<GetMeRoleModelDto, void>({
				path: `/users/me/role`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves audit logs of user actions within the system.
		 *
		 * @tags Users
		 * @name GetUser
		 * @summary Fetches audit logs for users, groups, and modifications, based on filtering criteria and permissions.
		 * @request GET:/users/{userID}
		 * @secure
		 */
		getUser: (
			userId: number,
			query: {
				columns: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PartialGetUserResponseDto, void>({
				path: `/users/${userId}`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint deletes a specific user by their ID
		 *
		 * @tags Users
		 * @name DeleteUser
		 * @summary Delete a specific user
		 * @request DELETE:/users/{userID}
		 * @secure
		 */
		deleteUser: (userId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/users/${userId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieves detailed information about a specific user by their user ID.
		 *
		 * @tags Users
		 * @name GetUserDetail
		 * @summary Fetches user details including basic profile information, status, and timestamps.
		 * @request GET:/users/{userID}/detail
		 * @secure
		 */
		getUserDetail: (userId: number, params: RequestParams = {}) =>
			this.request<GetUserResponseDto, void>({
				path: `/users/${userId}/detail`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves the list of user groups associated with a specific user by their user ID.
		 *
		 * @tags Users
		 * @name GetUserGroupsByUserId
		 * @summary Fetches user groups for the given user, ensuring permissions are respected.
		 * @request GET:/users/{userID}/userGroups
		 * @secure
		 */
		getUserGroupsByUserId: (userId: number, params: RequestParams = {}) =>
			this.request<UserGroupDto[], void>({
				path: `/users/${userId}/userGroups`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get language related to authenticated user. This endpoint retrieves the language preference associated with the authenticated user. The returned language code corresponds to a predefined enum (`ELanguageCode`) representing supported languages. If the authenticated user has a `clientID` instead of a `userID`, a **400 Bad Request** response will be returned, indicating that the request is invalid for this type of user. A successful response returns a **200 OK** status along with the user's language code.
		 *
		 * @tags Preferences
		 * @name MeLanguage
		 * @summary Get language for authenticated user
		 * @request GET:/users/me/preferences/language
		 * @secure
		 */
		meLanguage: (params: RequestParams = {}) =>
			this.request<ELanguageCode, void>({
				path: `/users/me/preferences/language`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Change language for the authenticated user. This endpoint allows the authenticated user to change their preferred language. The request body must contain a valid language code, defined in the `ChangeLanguageDto` object, which will be updated in the user's settings. If the language code provided is not supported or invalid, a **400 Bad Request** response will be returned. A successful request returns a **200 OK** status, indicating that the language preference has been successfully updated.
		 *
		 * @tags Preferences
		 * @name ChangeLanguage
		 * @summary Endpoint to change language
		 * @request POST:/users/me/preferences/language
		 * @secure
		 */
		changeLanguage: (data: ChangeLanguageDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/users/me/preferences/language`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Get language related to user by user ID. This endpoint allows retrieval of the language preference associated with a specific user, identified by `userID`. The returned language code corresponds to a predefined enum (`ELanguageCode`) representing supported languages. If the authenticated user has a `clientID` instead of a `userID`, a **400 Bad Request** response will be returned, indicating that the request is invalid for this type of user. A successful response returns a **200 OK** status along with the specified user's language code.
		 *
		 * @tags Preferences
		 * @name UserLanguage
		 * @summary Get language for a specific user
		 * @request GET:/users/{userID}/preferences/language
		 * @secure
		 */
		userLanguage: (userId: number, params: RequestParams = {}) =>
			this.request<ELanguageCode, void>({
				path: `/users/${userId}/preferences/language`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	userGroups = {
		/**
		 * @description Create a new user group
		 *
		 * @tags User Groups
		 * @name CreateUserGroup
		 * @summary Create a new user group
		 * @request POST:/userGroups
		 * @secure
		 */
		createUserGroup: (data: CreateUserGroupRequestDto, params: RequestParams = {}) =>
			this.request<CreateUserGroupResponseDto, void>({
				path: `/userGroups`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete an multiple user groups by provided IDs
		 *
		 * @tags User Groups
		 * @name DeleteUserGroups
		 * @summary Delete multiple user groups by provided IDs
		 * @request DELETE:/userGroups
		 * @secure
		 */
		deleteUserGroups: (
			query: {
				/** - The IDs of the user groups to delete */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/userGroups`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Get user groups paginated collection
		 *
		 * @tags User Groups
		 * @name GetUserGroups
		 * @summary Get user groups paginated collection
		 * @request GET:/userGroups
		 * @secure
		 */
		getUserGroups: (
			query?: {
				/**
				 * - The offset for paginating through the Identity Stores (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of Identity Stores to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columnsList?: string[];
				/**
				 * - An array of strings used for filtering user groups
				 * @default []
				 */
				filterList?: string[];
				/**
				 * - An array of strings used for sorting user groups
				 * @default []
				 */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetUserGroupResponseDto, void>({
				path: `/userGroups`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve a user group by ID
		 *
		 * @tags User Groups
		 * @name GetUserGroup
		 * @summary Get a user group by ID
		 * @request GET:/userGroups/{userGroupID}
		 * @secure
		 */
		getUserGroup: (userGroupId: number, params: RequestParams = {}) =>
			this.request<UserGroupDto, void>({
				path: `/userGroups/${userGroupId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update an existing user group
		 *
		 * @tags User Groups
		 * @name UpdateUserGroup
		 * @summary Update an existing user group entity
		 * @request PATCH:/userGroups/{userGroupID}
		 * @secure
		 */
		updateUserGroup: (userGroupId: number, data: UpdateUserGroupDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/userGroups/${userGroupId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete an user group by ID
		 *
		 * @tags User Groups
		 * @name DeleteUserGroup
		 * @summary Delete an user group by provided ID
		 * @request DELETE:/userGroups/{userGroupID}
		 * @secure
		 */
		deleteUserGroup: (userGroupId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/userGroups/${userGroupId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Add user to a user group
		 *
		 * @tags User Groups
		 * @name AddUserToUserGroup
		 * @summary Add user to a user group
		 * @request POST:/userGroups/{userGroupID}/users/{userID}
		 * @secure
		 */
		addUserToUserGroup: (userGroupId: number, userId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/userGroups/${userGroupId}/users/${userId}`,
				method: 'POST',
				secure: true,
				...params,
			}),

		/**
		 * @description Delete an user from a user group
		 *
		 * @tags User Groups
		 * @name DeleteUserFromUserGroup
		 * @summary Delete an user from a user group
		 * @request DELETE:/userGroups/{userGroupID}/users/{userID}
		 * @secure
		 */
		deleteUserFromUserGroup: (userGroupId: number, userId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/userGroups/${userGroupId}/users/${userId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Add multiple users to a user group
		 *
		 * @tags User Groups
		 * @name AddUsersToUserGroup
		 * @summary Add multiple users to a user group
		 * @request POST:/userGroups/{userGroupID}/users
		 * @secure
		 */
		addUsersToUserGroup: (userGroupId: number, data: number[], params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/userGroups/${userGroupId}/users`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete multiple users from a user group
		 *
		 * @tags User Groups
		 * @name DeleteUsersFromUserGroup
		 * @summary Delete multiple users from a user group
		 * @request DELETE:/userGroups/{userGroupID}/users
		 * @secure
		 */
		deleteUsersFromUserGroup: (
			userGroupId: number,
			query: {
				/** - IDs of the users */
				userIDs: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/userGroups/${userGroupId}/users`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Get paginated collection of users in a user group by group ID
		 *
		 * @tags User Groups
		 * @name GetUsersInUserGroup
		 * @summary Get paginated collection of users in a user group by group ID
		 * @request GET:/userGroups/{userGroupID}/users
		 * @secure
		 */
		getUsersInUserGroup: (
			userGroupId: number,
			query?: {
				/**
				 * - The offset for paginating through the Identity Stores (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of Identity Stores to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columnsList?: string[];
				/**
				 * - An array of strings used for filtering user groups
				 * @default []
				 */
				filterList?: string[];
				/**
				 * - An array of strings used for sorting user groups
				 * @default []
				 */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetUsersInGroupResponseDto, void>({
				path: `/userGroups/${userGroupId}/users`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get paged user details collection from a user group
		 *
		 * @tags User Groups
		 * @name GetUserDetailsInGroup
		 * @summary Get paged user details collection from a user group
		 * @request GET:/userGroups/{userGroupID}/users/detail
		 * @secure
		 */
		getUserDetailsInGroup: (
			userGroupId: number,
			query?: {
				/**
				 * - The offset for paginating through the Identity Stores (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of Identity Stores to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The columns to retrieve. Defaults to all columns if not provided.
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The filter criteria to apply.
				 * @default []
				 */
				filter?: string[];
				/**
				 * The sorting criteria to apply.
				 * @default []
				 */
				sort?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetUserDetailsInGroupResponseDto, void>({
				path: `/userGroups/${userGroupId}/users/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),
	};
	date = {
		/**
		 * @description This endpoint retrieves the current server date and time.
		 *
		 * @tags General
		 * @name GetDate
		 * @summary Get current date and time
		 * @request GET:/date
		 */
		getDate: (params: RequestParams = {}) =>
			this.request<GetDateResponseDto, any>({
				path: `/date`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	};
	status = {
		/**
		 * @description Get current setup progress step
		 *
		 * @tags Status
		 * @name GetSetupProgress
		 * @summary Get current setup progress step
		 * @request GET:/status/progress
		 */
		getSetupProgress: (params: RequestParams = {}) =>
			this.request<string, void>({
				path: `/status/progress`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Check APP status
		 *
		 * @tags Status
		 * @name GetAppStatus
		 * @summary Check APP status
		 * @request GET:/status/appStatus
		 */
		getAppStatus: (params: RequestParams = {}) =>
			this.request<EAppStatus, void>({
				path: `/status/appStatus`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * @description Checks if a user is registered based on the session ID in the cookies.
 *
 * @tags Status
 * @name Registered
 * @summary This method verifies the user's registration status by extracting the session ID from the cookies.
If the session ID is valid, it updates the setup progress and sets the token cookies.
 * @request GET:/status/registered
 */
		registered: (params: RequestParams = {}) =>
			this.request<
				{
					registered: boolean;
				},
				void
			>({
				path: `/status/registered`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	};
	setup = {
		/**
		 * @description Get current setup progress step
		 *
		 * @tags Setup
		 * @name GetSetupProgress
		 * @summary Get current setup progress step
		 * @request GET:/setup/progress
		 */
		getSetupProgress: (params: RequestParams = {}) =>
			this.request<ESetupStep, void>({
				path: `/setup/progress`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * @description Sets the setup progress to the previous step in the configuration process.
 *
 * @tags Setup
 * @name SetBackSetupProgress
 * @summary Moves the setup progress back one step based on the current progress.
This allows users to navigate backward through the setup steps if needed.
 * @request POST:/setup/progress/back
 */
		setBackSetupProgress: (params: RequestParams = {}) =>
			this.request<ESetupStep, void>({
				path: `/setup/progress/back`,
				method: 'POST',
				format: 'json',
				...params,
			}),

		/**
		 * @description Change the current language of the system
		 *
		 * @tags Setup
		 * @name ChangeSystemLanguage
		 * @request POST:/setup/systemLanguageChange
		 */
		changeSystemLanguage: (data: ChangeLanguageDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/setup/systemLanguageChange`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Create a new CSR
		 *
		 * @tags Setup
		 * @name CreateCsr
		 * @summary Create a new CSR
		 * @request POST:/setup/csr
		 */
		createCsr: (data: CreateCSRDto, params: RequestParams = {}) =>
			this.request<boolean, void>({
				path: `/setup/csr`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create a new SMTP configuration
		 *
		 * @tags Setup
		 * @name CreateSmtpConfiguration
		 * @summary Create a new SMTP configuration
		 * @request POST:/setup/smtp
		 */
		createSmtpConfiguration: (data: CreateSMTPMailConfigurationDto, params: RequestParams = {}) =>
			this.request<number, void>({
				path: `/setup/smtp`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get SMTP configuration
		 *
		 * @tags Setup
		 * @name GetSmtpConfiguration
		 * @summary Gets only existing SMTP configuration created during the setup process.
		 * @request GET:/setup/smtp
		 */
		getSmtpConfiguration: (params: RequestParams = {}) =>
			this.request<NullableReadSMTPMailConfigurationResponse, void>({
				path: `/setup/smtp`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Verify SMTP configuration
		 *
		 * @tags Setup
		 * @name Verify
		 * @summary Verify SMTP configuration
		 * @request POST:/setup/verify
		 */
		verify: (data: VerifySMTPMailConfigurationDto, params: RequestParams = {}) =>
			this.request<boolean, void>({
				path: `/setup/verify`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Save server setup
		 *
		 * @tags Setup
		 * @name CreateServerSetup
		 * @summary Save server setup
		 * @request POST:/setup/serverSetup
		 */
		createServerSetup: (data: ServerSetupDto, params: RequestParams = {}) =>
			this.request<boolean, void>({
				path: `/setup/serverSetup`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Getserver setup
		 *
		 * @tags Setup
		 * @name GetServerSetup
		 * @summary Save server setup
		 * @request GET:/setup/serverSetup
		 */
		getServerSetup: (params: RequestParams = {}) =>
			this.request<ServerSetupDto, void>({
				path: `/setup/serverSetup`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Restend SMTP verification code
		 *
		 * @tags Setup
		 * @name ResendSmtpVerificationCode
		 * @summary Resend SMTP verification code
		 * @request POST:/setup/resendSMTPVerify
		 */
		resendSmtpVerificationCode: (params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/setup/resendSMTPVerify`,
				method: 'POST',
				...params,
			}),

		/**
		 * @description Create a new identity store
		 *
		 * @tags Setup
		 * @name CreateIdentityStore
		 * @summary Create a new identity store
		 * @request POST:/setup/identityStore
		 */
		createIdentityStore: (data: CreateIdentityStoreDto, params: RequestParams = {}) =>
			this.request<number, void>({
				path: `/setup/identityStore`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get identity store
		 *
		 * @tags Setup
		 * @name GetIdentityStore
		 * @summary Get identity store
		 * @request GET:/setup/identityStore
		 */
		getIdentityStore: (params: RequestParams = {}) =>
			this.request<IdentityStoreDto | null, void>({
				path: `/setup/identityStore`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Authenticate identity store
		 *
		 * @tags Setup
		 * @name AuthenticateIdentityStore
		 * @summary Authenticate to identity store
		 * @request POST:/setup/authenticate
		 */
		authenticateIdentityStore: (data: AuthenticateIdentityStoreDto, params: RequestParams = {}) =>
			this.request<string, void>({
				path: `/setup/authenticate`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	tenants = {
		/**
		 * @description Return active tenant ID
		 *
		 * @tags Tenants
		 * @name Active
		 * @summary Get the active tenant ID of the authenticated user
		 * @request GET:/tenants/active
		 * @secure
		 */
		active: (params: RequestParams = {}) =>
			this.request<NullableNumber, void>({
				path: `/tenants/active`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve tenant information for a specified tenant by either its unique ID or slug.
		 *
		 * @tags Tenants
		 * @name GetTenant
		 * @summary Get tenant by ID or slug
		 * @request GET:/tenants/{tenantIdOrSlug}
		 * @secure
		 */
		getTenant: (tenantIdOrSlug: any, params: RequestParams = {}) =>
			this.request<TenantModel, void>({
				path: `/tenants/${tenantIdOrSlug}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves a paginated collection of tenants with optional filtering, sorting, and column selection.
		 *
		 * @tags Tenants
		 * @name GetTenants
		 * @summary Get paginated tenants collection
		 * @request GET:/tenants
		 * @secure
		 */
		getTenants: (
			query?: {
				/**
				 * - An array of filters to apply to the tenant records (optional).
				 * @default []
				 */
				filter?: string[];
				/**
				 * - An array of sorting criteria to apply to the tenant records (optional).
				 * @default []
				 */
				sort?: string[];
				/**
				 * - An array of columns to return; defaults to all columns if not specified (optional).
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * - The maximum number of records to return; defaults to 20 if not specified (optional).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset of the first record to return; defaults to 0 if not specified (optional).
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialTenantModel, void>({
				path: `/tenants`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description The tenant creation process involves multiple back-end steps, some of which may take time to complete. Consequently, tenant creation is handled asynchronously, and the response will include the ID of the tenant being created. The process begins by creating a tenant with the status **IN_PROGRESS**. After the tenant is created in the database, the server returns a **201 Created** response. Following this, provisioning for the tenant is initiated. Once provisioning is complete, the tenant's status is updated to **COMPLETED**. If any provisioning step fails, the tenant's status will be changed to **FAILED**.
		 *
		 * @tags Tenants
		 * @name CreateTenant
		 * @summary Create a new tenant
		 * @request POST:/tenants
		 * @secure
		 */
		createTenant: (data: CreateTenantRequestDto, params: RequestParams = {}) =>
			this.request<CreateTenantResponseDto, void>({
				path: `/tenants`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint permanently removes multiple tenants from the system. If any of the specified tenant IDs do not exist, a **404 Not Found** response will be returned for each non-existent tenant. Successful deletion results in a **204 No Content** response.
		 *
		 * @tags Tenants
		 * @name DeleteTenants
		 * @summary Delete multiple tenants by their IDs
		 * @request DELETE:/tenants
		 * @secure
		 */
		deleteTenants: (
			query: {
				/** An array of unique identifiers for the tenants to delete (must be valid numeric IDs). */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description This endpoint updates a tenant's details such as name, description, and network addresses. When updating network addresses, the existing addresses are replaced with the new ones provided.
		 *
		 * @tags Tenants
		 * @name UpdateTenant
		 * @summary Update tenant by ID
		 * @request PATCH:/tenants/{tenantID}
		 * @secure
		 */
		updateTenant: (tenantId: number, data: UpdateTenantRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete a tenant by its unique identifier. This endpoint permanently removes a tenant from the system. If the specified tenant ID does not exist, a **404 Not Found** response will be returned. Successful deletion results in a **204 No Content** response.
		 *
		 * @tags Tenants
		 * @name DeleteTenant
		 * @summary Delete a tenant by its ID
		 * @request DELETE:/tenants/{tenantID}
		 * @secure
		 */
		deleteTenant: (tenantId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Retry tenant provisioning
		 *
		 * @tags Tenants
		 * @name RetryCreateTenant
		 * @summary Retry tenant provisioning
		 * @request POST:/tenants/retry
		 * @secure
		 */
		retryCreateTenant: (data: RetryCreateTenantDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/retry`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	};
	tokens = {
		/**
		 * @description Return list of detailed tokens with optional filtering, sorting and pagination
		 *
		 * @tags Tokens
		 * @name GetTokensDetail
		 * @summary Get tokens
		 * @request GET:/tokens/detail
		 * @secure
		 */
		getTokensDetail: (
			query?: {
				/**
				 * Offset of first row to return
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * Maximum number of rows to return
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * Columns to return
				 * @default ["*"]
				 */
				columns?: string[];
				/** @default [] */
				filters?: string[];
				/**
				 * Sort expression to sort tokens
				 * @default []
				 */
				sort?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetTokenDetailResponseDto, void>({
				path: `/tokens/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return token information for token with given ID
		 *
		 * @tags Tokens
		 * @name GetToken
		 * @summary Get token by ID
		 * @request GET:/tokens/{tokenID}
		 * @secure
		 */
		getToken: (tokenId: number, params: RequestParams = {}) =>
			this.request<PartialTokenTableRowDto, void>({
				path: `/tokens/${tokenId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete a token by ID
		 *
		 * @tags Tokens
		 * @name DeleteToken
		 * @summary Delete token by ID
		 * @request DELETE:/tokens/{tokenID}
		 * @secure
		 */
		deleteToken: (tokenId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tokens/${tokenId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Return list of tokens with optional filtering, sorting and pagination
		 *
		 * @tags Tokens
		 * @name GetTokens
		 * @summary Get tokens
		 * @request GET:/tokens
		 * @secure
		 */
		getTokens: (
			query?: {
				/**
				 * Offset of first row to return
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * Maximum number of rows to return
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * Columns to return
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * Filter expression to filter tokens
				 * @default []
				 */
				filter?: string[];
				/**
				 * Sort expression to sort tokens
				 * @default []
				 */
				sort?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialTokenModel, void>({
				path: `/tokens`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete multiple tokens by their IDs
		 *
		 * @tags Tokens
		 * @name DeleteTokens
		 * @summary Delete multiple tokens by their IDs
		 * @request DELETE:/tokens
		 * @secure
		 */
		deleteTokens: (
			query: {
				/** The IDs of the tokens to delete */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tokens`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Return list of count of tokens for each application version for each platform
		 *
		 * @tags Tokens
		 * @name GetTokenApplicationVersions
		 * @summary Get application versions
		 * @request GET:/tokens/versions
		 * @secure
		 */
		getTokenApplicationVersions: (params: RequestParams = {}) =>
			this.request<TokenVersionsDto, void>({
				path: `/tokens/versions`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return list of count of tokens for each system version for each platform
		 *
		 * @tags Tokens
		 * @name GetTokenSystemVersions
		 * @summary Get system versions
		 * @request GET:/tokens/systemVersions
		 * @secure
		 */
		getTokenSystemVersions: (params: RequestParams = {}) =>
			this.request<TokenSystemVersionsDto, void>({
				path: `/tokens/systemVersions`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return count of online, offline and total tokens
		 *
		 * @tags Tokens
		 * @name GetTokenCount
		 * @summary Get token count
		 * @request GET:/tokens/count
		 * @secure
		 */
		getTokenCount: (params: RequestParams = {}) =>
			this.request<TokenCountDto, void>({
				path: `/tokens/count`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	securityPolicies = {
		/**
		 * @description Creates a new security policy
		 *
		 * @tags Security Policy
		 * @name CreateSecurityPolicy
		 * @summary Create a new security policy
		 * @request POST:/securityPolicies
		 * @secure
		 */
		createSecurityPolicy: (data: CreateSecurityPolicyRequestDto, params: RequestParams = {}) =>
			this.request<CreateSecurityPolicyResponseDto, void>({
				path: `/securityPolicies`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves a list of security policies
		 *
		 * @tags Security Policy
		 * @name GetSecurityPolicies
		 * @summary Get a list of security policies
		 * @request GET:/securityPolicies
		 * @secure
		 */
		getSecurityPolicies: (
			query?: {
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetSecurityPoliciesResponseDto, void>({
				path: `/securityPolicies`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Deletes multiple security policies by their IDs.
		 *
		 * @tags Security Policy
		 * @name DeleteSecurityPolicies
		 * @summary Delete multiple security policies by ID
		 * @request DELETE:/securityPolicies
		 * @secure
		 */
		deleteSecurityPolicies: (
			query: {
				/**
				 * The IDs of the security policies to delete.
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/securityPolicies`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieves a security policy by its ID.
		 *
		 * @tags Security Policy
		 * @name GetSecurityPolicy
		 * @summary Get a security policy by ID
		 * @request GET:/securityPolicies/{id}
		 * @secure
		 */
		getSecurityPolicy: (id: number, params: RequestParams = {}) =>
			this.request<SecurityPolicyModel, void>({
				path: `/securityPolicies/${id}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Updates a security policy by its ID.
		 *
		 * @tags Security Policy
		 * @name UpdateSecurityPolicy
		 * @summary Update a security policy by ID
		 * @request PUT:/securityPolicies/{id}
		 * @secure
		 */
		updateSecurityPolicy: (id: number, data: CreateSecurityPolicyRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/securityPolicies/${id}`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Deletes a security policy by its ID.
		 *
		 * @tags Security Policy
		 * @name DeleteSecurityPolicy
		 * @summary Delete a security policy by ID
		 * @request DELETE:/securityPolicies/{id}
		 * @secure
		 */
		deleteSecurityPolicy: (id: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/securityPolicies/${id}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),
	};
	securityPolicy = {
		/**
		 * @description Retrieves user groups for a specific security policy.
		 *
		 * @tags Security Policy
		 * @name GetUserGroupsForSecurityPolicy
		 * @summary Get user groups for a specific security policy
		 * @request GET:/securityPolicy/{id}/userGroups
		 * @secure
		 */
		getUserGroupsForSecurityPolicy: (
			id: number,
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetGroupResponseDto, void>({
				path: `/securityPolicy/${id}/userGroups`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves geofences for a specific security policy.
		 *
		 * @tags Security Policy
		 * @name GetGeofencesForSecurityPolicy
		 * @summary Get geofences for a specific security policy
		 * @request GET:/securityPolicy/{id}/geofences
		 * @secure
		 */
		getGeofencesForSecurityPolicy: (id: number, params: RequestParams = {}) =>
			this.request<GeofenceModel[], void>({
				path: `/securityPolicy/${id}/geofences`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves PAM target groups for a specific security policy.
		 *
		 * @tags Security Policy
		 * @name GetPamTargetGroupsForSecurityPolicy
		 * @summary Get PAM target groups for a specific security policy
		 * @request GET:/securityPolicy/{id}/pamTargetGroups
		 * @secure
		 */
		getPamTargetGroupsForSecurityPolicy: (
			id: number,
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetGroupResponseDto, void>({
				path: `/securityPolicy/${id}/pamTargetGroups`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves rule sets for a specific security policy
		 *
		 * @tags Security Policy
		 * @name GetRuleSetsForSecurityPolicy
		 * @summary Get rule sets for a specific security policy
		 * @request GET:/securityPolicy/{id}/ruleSets
		 * @secure
		 */
		getRuleSetsForSecurityPolicy: (
			id: number,
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetGroupResponseDto, void>({
				path: `/securityPolicy/${id}/ruleSets`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves detailed rule sets for a specific security policy
		 *
		 * @tags Security Policy
		 * @name GetDetailedRuleSetsForSecurityPolicy
		 * @summary Get detailed rule sets for a specific security policy
		 * @request GET:/securityPolicy/{id}/ruleSets/detail
		 * @secure
		 */
		getDetailedRuleSetsForSecurityPolicy: (
			id: number,
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetDetailedRuleSetsResponseDto, void>({
				path: `/securityPolicy/${id}/ruleSets/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),
	};
	ruleSets = {
		/**
		 * @description Creates a new rule set
		 *
		 * @tags Rule Set
		 * @name CreateRuleSet
		 * @summary Create a new rule set
		 * @request POST:/ruleSets
		 * @secure
		 */
		createRuleSet: (data: CreateRuleSetRequestDto, params: RequestParams = {}) =>
			this.request<CreateSecurityPolicyResponseDto, void>({
				path: `/ruleSets`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get rule set collection
		 *
		 * @tags Rule Set
		 * @name GetRuleSetCollection
		 * @summary Get rule set collection
		 * @request GET:/ruleSets
		 * @secure
		 */
		getRuleSetCollection: (
			query?: {
				/** @default [] */
				filters?: string[];
				/**
				 * The sort order to apply to search results
				 * @default []
				 */
				sort?: string[];
				/** @default ["*"] */
				columns?: string[];
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetRuleSetCollectionDto, void>({
				path: `/ruleSets`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete a rule sets by IDs
		 *
		 * @tags Rule Set
		 * @name DeleteRuleSets
		 * @summary Delete a rule sets by IDs
		 * @request DELETE:/ruleSets
		 * @secure
		 */
		deleteRuleSets: (
			query: {
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/ruleSets`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Updates a rule set by ID
		 *
		 * @tags Rule Set
		 * @name UpdateRuleSet
		 * @summary Update a rule set by ID
		 * @request PUT:/ruleSets/{id}
		 * @secure
		 */
		updateRuleSet: (id: number, data: UpdateRuleSetRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/ruleSets/${id}`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Get system rule set collection
		 *
		 * @tags Rule Set
		 * @name GetSystemRuleSetCollection
		 * @summary Get system rule set collection
		 * @request GET:/ruleSets/system
		 * @secure
		 */
		getSystemRuleSetCollection: (
			query?: {
				/** @default [] */
				filters?: string[];
				/**
				 * The sort order to apply to search results
				 * @default []
				 */
				sort?: string[];
				/** @default ["*"] */
				columns?: string[];
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetRuleSetCollectionDto, void>({
				path: `/ruleSets/system`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve rule set information by ID
		 *
		 * @tags Rule Set
		 * @name GetRuleSet
		 * @summary Get rule set by ID
		 * @request GET:/ruleSets/{ruleSetID}
		 * @secure
		 */
		getRuleSet: (ruleSetId: number, params: RequestParams = {}) =>
			this.request<GetRuleSetResponseDto, void>({
				path: `/ruleSets/${ruleSetId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete a rule set by ID
		 *
		 * @tags Rule Set
		 * @name DeleteRuleSet
		 * @summary Delete a rule set by ID
		 * @request DELETE:/ruleSets/{ruleSetID}
		 * @secure
		 */
		deleteRuleSet: (ruleSetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/ruleSets/${ruleSetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),
	};
	tenantResources = {
		/**
		 * @description Return resources information for the given tenant ID
		 *
		 * @tags TenantResources
		 * @name GetTenantResources
		 * @summary Get tenant resources by tenant ID
		 * @request GET:/tenant-resources
		 * @secure
		 */
		getTenantResources: (
			query?: {
				/**
				 * - The offset for paginating (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number items to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * - An array of strings used for filtering
				 * @default []
				 */
				filter?: string[];
				/**
				 * - An array of strings used for sorting
				 * @default []
				 */
				sort?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetTenantResourcesResponseDto, void>({
				path: `/tenant-resources`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),
	};
	actions = {
		/**
		 * @description Get action list
		 *
		 * @tags Actions
		 * @name GetActions
		 * @summary Get action list
		 * @request GET:/actions
		 * @secure
		 */
		getActions: (
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetActionCollectionEntityDto, void>({
				path: `/actions`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get action by ID
		 *
		 * @tags Actions
		 * @name GetActionById
		 * @summary Get action by ID
		 * @request GET:/actions/{actionID}
		 * @secure
		 */
		getActionById: (actionId: number, params: RequestParams = {}) =>
			this.request<GetActionDetailDto, void>({
				path: `/actions/${actionId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get action results by action ID
		 *
		 * @tags Actions
		 * @name GetActionResultCollection
		 * @summary Get action results paginated collection by action ID
		 * @request GET:/actions/{actionID}/results
		 * @secure
		 */
		getActionResultCollection: (actionId: number, params: RequestParams = {}) =>
			this.request<GetActionResultDto[], void>({
				path: `/actions/${actionId}/results`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	networkPolicies = {
		/**
		 * @description Update the network policy for a tenant. This endpoint removes all existing network addresses and replaces them with the new ones provided in the request. These network addresses are critical for restricting access to the tenant's resources, ensuring that only authorized IPs can interact with them. If the specified tenant does not exist, a **404 Not Found** response will be returned. A successful update results in a **204 No Content** response, indicating that the operation completed successfully without returning any content.
		 *
		 * @tags networkPolicies
		 * @name CreateNetworkPolicy
		 * @summary Update network policy for a tenant
		 * @request PUT:/networkPolicies
		 * @secure
		 */
		createNetworkPolicy: (data: CreateNetworkPolicyDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/networkPolicies`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description This endpoint retrieves a paginated collection of network policies for a tenant, providing a paginated response. If the specified tenant does not exist, a **404 Not Found** response will be returned. A successful fetch results in a **200 OK** response containing the network policies.
		 *
		 * @tags networkPolicies
		 * @name GetNetworkPolicies
		 * @summary Get paginated network policies collection
		 * @request GET:/networkPolicies
		 * @secure
		 */
		getNetworkPolicies: (params: RequestParams = {}) =>
			this.request<GetNetworkPoliciesResponseDto, void>({
				path: `/networkPolicies`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
}
