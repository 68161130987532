import { useCallback, useMemo } from 'react';
import { Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';
import { ContentCopy as ContentCopyIcon, Help as HelpIcon } from '@mui/icons-material';
import { Box, Chip, IconButton, Paper, Stack, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import copy from 'copy-to-clipboard';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef as MRTColumnDef } from 'material-react-table';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { ServiceName, Versions, AppService } from './types';
import { mapServiceName } from './utils';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { useMRTLocalization } from '../../hooks/useTableLocalization';

export const About = () => {
	const { t } = useTranslation();

	const api = useSwaggerApi();

	const { data, error, isLoading, isRefetching } = useQuery({
		queryKey: [EQueryKey.VERSIONS_QUERY],
		queryFn: async () => {
			try {
				const { data } = await api.settings.getVersions();

				return data;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		},
		select: (data) => {
			if (data) {
				return {
					...data,
					dashboard: (process.env.REACT_APP_VERSION as string) ?? null,
				} as Versions;
			}
		},
	});

	const appServices: AppService[] = useMemo(() => {
		if (data) {
			return Object.entries(data).map(([key, value]) => ({
				name: mapServiceName(key as ServiceName),
				version: value as Versions[keyof Versions],
			}));
		}

		return [];
	}, [data]);

	const copyToClipboard = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, content: string) => {
			event.preventDefault();

			copy(content);
			enqueueSnackbar(t('page.about.versionCopiedToClipboard'), {
				variant: 'success',
			});
		},
		[t],
	);

	const { MRTLocalization } = useMRTLocalization();

	const columns: MRTColumnDef<AppService>[] = useMemo(
		() => [
			{
				accessorKey: 'name',
				header: t('page.about.table.header.name'),
			},
			{
				accessorKey: 'version',
				header: t('page.about.table.header.version'),
				Cell: ({ row }) => row.original.version || t('page.about.table.notAvailable'),
			},
			// {
			// 	accessorKey: 'running',
			// 	header: t('page.about.table.header.status'),
			// 	filterVariant: 'select',
			// 	filterSelectOptions: [
			// 		{
			// 			label: t('page.about.status.running'),
			// 			value: true,
			// 		},
			// 		{
			// 			label: t('page.about.status.stopped'),
			// 			value: false,
			// 		},
			// 	],
			// 	filterFn: (row, _, filterValue) => row.original.running === filterValue,
			// 	Cell: ({ row }) => {
			// 		if (row.original.running) {
			// 			return <Chip icon={<DoneIcon />} label={t('page.about.status.running')} color='success' />;
			// 		}

			// 		return <Chip icon={<CloseIcon />} label={t('page.about.status.stopped')} color='error' />;
			// 	},
			// },
		],
		[t],
	);

	const table = useMaterialReactTable({
		columns,
		data: appServices,
		enableRowActions: true,
		initialState: { density: 'compact' },
		localization: MRTLocalization,
		muiToolbarAlertBannerProps: {
			color: 'error',
			children: error ? <>{error}</> : undefined,
		},
		positionActionsColumn: 'last',
		rowCount: appServices.length,
		state: {
			isLoading,
			showAlertBanner: error !== null,
			showProgressBars: isRefetching,
		},
		getRowId: (row) => row.name || '',
		muiTablePaperProps: ({ table }) => ({
			style: {
				zIndex: table.getState().isFullScreen ? 1250 : undefined,
				boxShadow: 'none',
				outline: '1px solid #e0e0e0',
			},
		}),
		renderRowActions: ({ row }) => (
			<Tooltip title={t('page.about.copyToClipboardTooltip')} enterDelay={500} arrow>
				<IconButton
					aria-label='copy'
					size='small'
					onClick={(event) => copyToClipboard(event, `${row.original.name}: ${row.original.version}`)}
				>
					<ContentCopyIcon />
				</IconButton>
			</Tooltip>
		),
	});

	return (
		<Box>
			<Paper elevation={3}>
				<Stack
					spacing={2}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.about.title')}
						description={t('page.about.description')}
						icon={HelpIcon}
					/>
					<MaterialReactTable table={table} />
				</Stack>
			</Paper>
		</Box>
	);
};
